import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GearIcon } from '../../components/Icons';
import Modal from '../../components/Modal';
import Navigation from '../../components/Navigation';
import TableButton from '../../components/Table/TableButton';
import EditCustomizingModal from './EditCustomizingModal';
import Table from './Table';

const ArticleConsumptions = ({ article_id, plant_protection }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const { t } = useTranslation()

  const tableToolbar = () => {
    return (
      <TableButton
        type="button"
        className="btn-light-primary me-3"
        icon={<GearIcon />}
        onClick={() => setModalOpen(true)}
      />
    )
  }

  const navItems = [
    { href: `/articles/${article_id}`, label: t('master_data') },
    { href: `/articles/${article_id}/purchases`, label: t('purchases') },
    { href: `/articles/${article_id}/show_disposed`, label: t('planned') },
    { href: `/articles/${article_id}/consumptions`, label: t('consumptions'), active: true },
    { href: `/articles/${article_id}/bills`, label: t('sales') }
  ]

  return (
    <>
      <Navigation items={navItems}/>
      <Modal className="modal fade show w-750px mw-750px" open={modalOpen} onClose={() => setModalOpen(false)} title={t('stock_settings')}>
        <EditCustomizingModal onClose={() => setModalOpen(false)} plantProtection= {plant_protection} />
      </Modal>
      <Table tableToolbar={tableToolbar} articleId={article_id} />
    </>
  )
}

export default ArticleConsumptions;
