import React, { useEffect } from "react";
import { Tooltip } from 'bootstrap'
import DateRangePicker from "../../../components/DateRangePicker";
import { useTranslation } from 'react-i18next';

const StaffOrder = ({ staffOrder, onEdit}) => {
    const { t, i18n } = useTranslation()


    useEffect(() => {
        const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
        const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl))
    }, []);



    function trim(text, length = 10) {
        return text.length > 10 ? text.substring(0, length) + "..." : text;
    }

    return <div className="d-flex flex-stack py-5 border-bottom border-gray-300 border-bottom-dashed">
        <div className="d-flex align-items-center">
            <div className="">
                <a href="#" className="d-flex align-items-center fs-5 fw-bold text-gray-900 text-hover-primary">Emma
                    Smith
                    <span
                        className="badge badge-light fs-8 fw-semibold ms-2">{trim("Gespann")}</span></a>
                <button
                    className="badge badge-light fs-8 fw-semibold badge-warning border-0">{trim("Güllefahren mit dem Langen transporter und coolem anbaugerärt")}</button>
                <button
                    className="badge badge-light fs-8 fw-semibold ms-2 border-0">{trim("Güllefahren mit dem Langen transporter und coolem anbaugerärt")}</button>

                <button data-bs-toggle="tooltip" data-bs-custom-class="tooltip-inverse" data-bs-placement="bottom" title="Güllefahren mit dem Langen transporter und coolem anbaugerärt"
                    className="badge badge-light fs-8 fw-semibold ms-2 border-0">{trim("Güllefahren mit dem Langen transporter und coolem anbaugerärt")}</button>
            </div>
        </div>
        <div className="d-flex">
            <div className="text-end">
                <DateRangePicker
                    placeholder=''
                    language={i18n.language}
                    classList="form-control form-control-sm form-control-solid text-center"
                    options={{
                        timePicker: true,
                        showDropdowns: true,
                        startDate: new Date(),
                        endDate: new Date(),
                        onChange: console.log,
                        autoApply: false
                    }}
                />

                <button data-bs-toggle="tooltip" data-bs-custom-class="tooltip" data-bs-placement="bottom"
                        title="Güllefahren mit dem Langen transporter und coolem anbaugerärt"
                        className="badge badge-secondary fs-8 fw-semibold ms-2 border-0">{trim("Güllefahren mit dem Langen transporter und coolem anbaugerärt", 20)}</button>
            </div>
        </div>
    </div>
}

export default StaffOrder
