import React, { useMemo, useState} from 'react';
import { axiosInstance, routes } from '../../utils/api_base';
import { useTranslation } from 'react-i18next';
import { pushToHistory } from '../../utils/base';
import BaseTable from '../../components/Table';
import { isEmpty } from 'lodash';
import ChartPie from '../../components/Icons/ChartPie';

const Table = ({ filters, tableToolbar }) => {
  const [totalRow, setTotalRow] = useState([])

  const { t, i18n } = useTranslation()

  const getServiceGroupStatistic = async (pagination, sorting, globalFilter, customFilters) => {
    const { pageIndex, pageSize } = pagination

    const sortQuery = sorting.map((attr) => {
      return attr.desc ? `${attr.id} desc` : attr.id
    })

    let params = { page: pageIndex + 1, limit: pageSize, sort: sortQuery[0] }
    if (globalFilter) {
      params.query = globalFilter
    }
    if (!isEmpty(customFilters)) {
      params = { ...params, ...customFilters }
    }
    const res = await axiosInstance.get(routes.serviceGroupStatistics(), { params: params })
    pushToHistory(params)
    setTotalRow(res.data.meta.total_row)
    return ({rows: res.data.data, pageCount: res.data.meta.total_pages, rowCount: res.data.meta.total_count})
  }

  const profitIcon = (row) => {
    const iconClass = row.original.attributes.icon_color
    const link = row.original.attributes.link

    return (
      <a href={link}>
        <ChartPie className={iconClass} />
      </a>
    )
  }

  const colorStyle = (color) => {
    switch (color) {
      case 'green':
        return '#17c653'
      case 'red':
        return '#f8285a'
      case 'gold':
        return '#f6c000'
      case 'gray':
        return '#78829d'

      default: return '';
    }
  }

  const tableFooter = () => {
    return (
      (totalRow &&
        <tr className='fw-bold'>
          <td>{t('shared.total')}</td>
          <td></td>
          <td>{totalRow.revenue} </td>
          <td>{totalRow.cost}</td>
          <td>{totalRow.dif}</td>
          <td>
            <svg width="16" height="16">
              <circle cx="8" cy="8" r="8" fill={colorStyle(totalRow.icon_color)}></circle>
            </svg>
          </td>
        </tr>
      )
    )
  }

  const columns = useMemo(
    () => [
      {
        id: 'name',
        header: t('shared.performance_groups'),
        accessorFn: row => row?.attributes?.name,
        cell: row => row.getValue(),
        size: 170,
        minSize: 170,
      },
      {
        id: 'service_counts',
        accessorFn: row => row?.attributes?.service_counts,
        header: t('shared.services'),
        size: 70,
        minSize: 70,
        cell: row => row.getValue(),
      },
      {
        id: 'revenue',
        accessorFn: row => row?.attributes?.revenue,
        header: t('shared.revenue'),
        cell: row => row.getValue(),
      },
      {
        id: 'costs',
        accessorFn: row => row?.attributes?.cost,
        header: t('shared.costs'),
        cell: row => row.getValue(),
      },
      {
        id: 'profit_lost',
        accessorFn: row => row?.attributes?.profit_loss,
        header: t('shared.profit_loss'),
        size: 70,
        minSize: 70,
        cell: row => row.getValue(),
      },
      {
        id: 'link',
        accessorFn: row => row?.attributes?.link,
        header: '',
        enableSorting: false,
        size: 50,
        minSize: 50,
        cell: ({ row }) => {
          return profitIcon(row)
        },
      },
    ],
    [i18n.language]
  )

  return (
    <BaseTable
      columns={columns}
      columnVisibility={{}}
      getDataFn={getServiceGroupStatistic}
      tableToolbar={tableToolbar}
      filters={filters}
      initialSortDirection='asc'
      tableFooter={tableFooter()}
    />
  )
}

export default Table;
