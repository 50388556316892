import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { axiosInstance, routes } from '../../utils/api_base';
import { useTranslation } from 'react-i18next';
import { pushToHistory } from '../../utils/base';
import BaseTable from '../Table';
import TableButton from '../Table/TableButton';
import { ExportIcon, FilterIcon, PlusIcon } from '../Icons';
import StatusLabel from '../../core/StatusLabel';
import Modal from '../Modal';
import Create from './create';

const Offers = ({ show_creator }) => {
  const { t, i18n } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);

  const getOffers = async (pagination, sorting, globalFilter) => {
    const { pageIndex, pageSize } = pagination
    const sortQuery = sorting.map((attr) => {
      return attr.desc ? `${attr.id} desc` : attr.id
    })
    const params = { page: pageIndex + 1, limit: pageSize, sort: sortQuery[0] }
    if (globalFilter) {
      params.query = globalFilter
    }
    const res = await axiosInstance.get(routes.offers(), { params: params })
    pushToHistory(params)

    return (
      { rows: res.data.data, pageCount: res.data.meta.total_pages, rowCount: res.data.meta.total_count }
    )
  }

  const tableToolbar = () => {
    return (
      <>
        <TableButton type="button" className="btn-light-primary me-3" icon={<FilterIcon />} title={t('shared.filter')} />
        <TableButton type="button" className="btn-light-primary me-3" icon={<ExportIcon />} title={t('shared.export')} />
        <TableButton
          className="btn-primary"
          onClick={() => setModalOpen(true)}
          icon={<PlusIcon />}
          title={t('new')}
        />
      </>
    )
  }

  const codeHtml = (row) => {
    return (
      <TableButton
        target="_blank"
        href={row?.original?.attributes?.link}
        className="btn-light-primary me-3"
        title={row?.original?.attributes?.short_name}
      />
    )
  }

  const servicesHtml = (row) => {
    const services = row.original.attributes?.service_features_names || []
    return (
      <div>
        <span title={services.join("\n\n")}>{services[0]}</span>
      </div>
    )
  }

  const columns = useMemo(
    () => [
      {
        id: 'code',
        header: t('shared.offer'),
        accessorFn: row => row?.attributes?.name,
        size: 70,
        minSize: 70,
        cell: ({ row }) => {
          return codeHtml(row)
        },
      },
      {
        id: 'customer',
        accessorFn: row => row?.attributes?.business_partner_name,
        header: t('shared.customer'),
        cell: row => row.getValue(),
      },
      {
        id: 'services',
        accessorFn: row => row?.attributes?.service_features_names,
        header: t('shared.services'),
        enableSorting: false,
        cell: ({ row }) => {
          return servicesHtml(row)
        },
      },
      {
        id: 'status',
        accessorFn: row => row?.attributes?.status,
        header: t('shared.status'),
        cell: ({ row }) => {
          return <StatusLabel code={row.original.attributes?.status_code?.toLowerCase()} />
        },
      },
      {
        id: 'date',
        accessorFn: row => row?.attributes?.offer_date,
        header: t('shared.date'),
        cell: row => row.getValue(),
      },
    ],
    [i18n.language]
  )

  return (
    <>
      <Modal className="modal fade show" open={modalOpen} onClose={() => setModalOpen(false)} title={t('create_offer')}>
        <Create onClose={() => setModalOpen(false)} />
      </Modal>
      <BaseTable columns={columns} columnVisibility={{}} getDataFn={getOffers} tableToolbar={tableToolbar} />
    </>
  )
}

Offers.propTypes = {
  show_creator: PropTypes.bool.isRequired,
};

export default Offers
