import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Table from './Table';

const DocumentOfBookingReports = ({}) => {
  const tableToolbar = () => { return (<></>) }

  return (
    <Table tableToolbar={tableToolbar} />
  )
}

export default DocumentOfBookingReports;
