import React, { useMemo } from 'react';
import BaseTable from '../../components/Table';
import { isEmpty } from 'lodash';
import { axiosInstance, routes } from '../../utils/api_base';
import { pushToHistory } from '../../utils/base';
import { useTranslation } from 'react-i18next';
import TableButton from '../../components/Table/TableButton';

const Table = ({ articleId, tableToolbar }) => {
  const { t, i18n } = useTranslation()

  const getArticlePurchases = async (pagination, sorting, globalFilter, customFilters) => {
    const { pageIndex, pageSize } = pagination
    const sortQuery = sorting.map((attr) => {
      return attr.desc ? `${attr.id} desc` : attr.id
    })
    let params = { page: pageIndex + 1, limit: pageSize, sort: sortQuery[0] }
    if (globalFilter) {
      params.query = globalFilter
    }
    if (!isEmpty(customFilters)) {
      params = { ...params, ...customFilters }
    }
    const res = await axiosInstance.get(routes.articlePurchases(), {
      params: {
        ...params,
        article_id: articleId
      }
    })
    pushToHistory(params)

    return(
      { rows: res.data.data, pageCount: res.data.meta.total_pages, rowCount: res.data.meta.total_count }
    )
  }

  const billNameHtml = (row) => {
    return(
      <TableButton
        href={row?.original?.attributes?.bill_link}
        className="btn-light-primary me-3"
        title={row?.original?.attributes?.bill_name}
      />
    )
  }

  const businessPartnerNameHtml = (row) => {
    return(
      <a href={row?.original?.attributes?.business_partner_link}>
        {row?.original?.attributes?.business_partner_name}
      </a>
    )
  }

  const columns = useMemo(
    () => [
      {
        id: 'bill_name',
        header: t('incoming_invoice'),
        accessorFn: row => row?.attributes?.bill_name,
        enableSorting: true,
        cell: ({ row }) => {
          return billNameHtml(row)
        },
      },
      {
        id: 'bill_date',
        accessorFn: row => row?.attributes?.bill_date,
        header: t('shared.date'),
        enableSorting: false,
        cell: row => row.getValue()
      },
      {
        id: 'business_partner_name',
        accessorFn: row => row?.attributes?.business_partner_name,
        header: t('supplier'),
        enableSorting: false,
        cell: ({ row }) => {
          return businessPartnerNameHtml(row)
        },
      },
      {
        id: 'quantity_txt',
        accessorFn: row => row?.attributes?.quantity_txt,
        header: t('shared.quantity'),
        enableSorting: false,
        cell: row => row.getValue()
      },
      {
        id: 'purchase_price',
        accessorFn: row => row?.attributes?.purchase_price,
        header: t('prices'),
        enableSorting: false,
        cell: row => row.getValue()
      },
    ],
    [i18n.language]
  )

  return (
    <BaseTable
      columns={columns}
      getDataFn={getArticlePurchases}
      initialSortDirection={'asc'}
      tableToolbar={tableToolbar}
    />
  )
}

export default Table;
