import { useDrop } from 'react-dnd';
import React, {useEffect, useRef, useState} from "react";
import itemRenderer from "./Timeline/OrderItem";
import groupRenderer from "./Timeline/GroupRenderer";
import { useTranslation } from 'react-i18next';

import Timeline, {
    TimelineHeaders,
    DateHeader,
    TodayMarker,
    CustomMarker,
    TimelineMarkers,
} from "react-calendar-timeline/lib";

const keys = {
    groupIdKey: "id",
    groupTitleKey: "title",
    groupRightTitleKey: "rightTitle",
    itemIdKey: "id",
    itemTitleKey: "title",
    itemDivTitleKey: "title",
    itemGroupKey: "group",
    itemTimeStartKey: "start",
    itemTimeEndKey: "end",
    groupLabelKey: "title"
};

const CustomTimeline = ({ onAdd, onDrop, groups, items, onTimeChange, defaultTimeStart, defaultTimeEnd }) => {
    const [defaultTime, setDefaultTime] = useState({ defaultTimeStart, defaultTimeEnd })
    const { t, i18n } = useTranslation()

    useEffect(() => {
        setDefaultTime({ defaultTimeStart, defaultTimeEnd })
    }, [defaultTimeStart, defaultTimeEnd])


    const timelineRef = useRef(null);

    const headerHeight = 62;

    const [{ isOver }, drop] = useDrop({
        accept: 'BOX',
        drop: (item, monitor) => {
            const delta = monitor.getClientOffset();
            const boundingRect = timelineRef.current.container.getBoundingClientRect();
            const offsetX = delta.x - boundingRect.left;
            const offsetY = delta.y - boundingRect.top - headerHeight
            const groupIndex = Math.floor(offsetY / boundingRect.height * groups.length);
            const group = groups[groupIndex];
            const time = timelineRef.current.canvasTimeStart + (offsetX / boundingRect.width) * (timelineRef.current.canvasTimeEnd - timelineRef.current.canvasTimeStart);
            onDrop(item, group, time);
        },
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
        }),
    });

    useEffect(() => {
        if(!timelineRef.current) return

        drop(timelineRef.current.container);
    }, [drop, timelineRef.current]);

    return (
        <Timeline
            ref={timelineRef}
            groups={groups}
            items={items}
            keys={keys}
            itemsSorted
            itemTouchSendsClick={false}
            stackItems
            itemHeightRatio={0.75}
            showCursorLine
            buffer={5}
            canResize={true}
            canScroll={false}
            onBoundsChange={onTimeChange}
            onCanvasClick={onAdd} // Add Order
            defaultTimeStart={defaultTime.defaultTimeStart}
            defaultTimeEnd={defaultTime.defaultTimeEnd}
            itemRenderer={itemRenderer}
            groupRenderer={groupRenderer}
        >
            <TimelineHeaders className="sticky">
                <DateHeader unit="primaryHeader"/>
                <DateHeader/>
            </TimelineHeaders>

            <TimelineMarkers>
                <TodayMarker/>
                <CustomMarker date={(new Date()).valueOf()}>
                    {/* custom renderer for this marker */}
                    {({styles, date}) => {
                        const customStyles = {
                            ...styles,
                            backgroundColor: 'deeppink',
                            width: '4px'
                        }
                        return <div style={customStyles}/>
                    }}
                </CustomMarker>
            </TimelineMarkers>
        </Timeline>
    );
}

export default CustomTimeline
