import React, { useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Toastr from '../Toastr';
import { axiosInstance, routes } from '../../utils/api_base';
import { Form, Field } from 'react-final-form'
import { isRequired, composeValidators } from '../Form/validations';
import TableButton from '../Table/TableButton';
import QuestionMark from '../Icons/QuestionMark';
import ErrorField from '../Form/ErrorField';
import AsyncSelectAdapter from '../Form/AsyncSelectAdapter';
import { Tooltip } from 'bootstrap'
import debounce from "lodash/debounce";
import { mapValues } from 'lodash';
import { FORM_ERROR } from 'final-form'

const Create = ({ onClose }) => {
  const { t } = useTranslation();

  useEffect(() => {
    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
    const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl))
  }, [onClose]);

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        onClose()
      }
    }
    window.addEventListener('keydown', close)
    return () => window.removeEventListener('keydown', close)
  }, [])

  const onSubmit = async values => {
    const data = {
      article: {
        client_specific_number: values.client_specific_number,
        name: values.name,
        article_detail_attributes: {
          factory_number: values.factoryNumber,
          bill_name: values.billName,
          unit_id: values.unitId?.value,
          revenue_code_id: values.revenueCodeId?.value,
          article_group_id: values.articleGroupId?.value
        }
      }
    };

    return axiosInstance.post(routes.articles(), data)
      .then(response => {
        Toastr({
          title: t('shared.success'),
          message: response.data.message,
          options: { closeButton: true }
        })

        if (response.data.redirect_url) window.location.href = response.data.redirect_url;

        onClose()
      }).catch(error => {

        const errorData = error.response.data
        if (errorData.error) {
          console.log(errorData.error)
          return mapValues(errorData.error, (val) => (val.join(', ')))
        } else {
          return { [FORM_ERROR]: error.message }
        }
      })
  }

  const fetchData = (url, params) => {
    return axiosInstance.get(url, { params })
      .then(response => response.data.data.map(item => ({
        value: item.id,
        label: item.attributes.name,
      })))
      .catch(error => {
        console.log('ERROR', error);
        return [];
      });
  };

  const createLoadOptions = (fetchFunction) =>
    useCallback(
      debounce((inputText, callback) => {
        fetchFunction(inputText).then(options => callback(options));
      }, 1000),
      []
    );

  const loadUnitOptions = createLoadOptions((inputText) => {
    const params = { page: 1, limit: 20, sort: 'name', query: inputText };
    return fetchData(routes.units(), params);
  });

  const loadArticleGroupOptions = createLoadOptions((inputText) => {
    const params = { page: 1, limit: 20, sort: 'name', query: inputText };
    return fetchData(routes.articleGroups(), params);
  });

  const loadRevenueCodeOptions = createLoadOptions((inputText) => {
    const params = { page: 1, limit: 20, sort: 'name', query: inputText };
    return fetchData(routes.revenueCodes(), params);
  });

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        clientSpecificNumber: '',
        factoryNumber: '',
        name: '',
        billName: '',
        articleGroupId: '',
        revenueCodeId: '',
        unitId: ''
      }}
      validate={values => {
        let errors = {}
        errors.articleGroupId = isRequired(values.articleGroupId)
        errors.name = composeValidators(isRequired)(values.name)
        errors.unitId = composeValidators(isRequired)(values.unitId)
        errors.revenueCodeId = composeValidators(isRequired)(values.revenueCodeId)
        return errors
      }}
      render={({ submitError, handleSubmit, submitting, pristine }) => (
        <form onSubmit={handleSubmit}>
          <>
            <div className="container">
              <div className="row mb-3">
                <div className="col">
                  <label>{t('shared.article_number')}</label>
                  <Field
                    name="client_specific_number"
                    component="input"
                    type="text"
                    className="form-control"
                  />
                  <ErrorField name="client_specific_number" />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col">
                  <label>{t('shared.manufacturer_number')}</label>
                  <Field
                    name="factoryNumber"
                    component="input"
                    type="text"
                    className="form-control"
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col">
                  <label className='required'>{t('shared.name')}</label>
                  <Field
                    name="name"
                    component="input"
                    type="text"
                    className="form-control"
                  />
                  <ErrorField name="name" />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col">
                  <label>{t('shared.invoice_text')}</label>
                  <span
                    data-bs-toggle="tooltip"
                    data-bs-custom-class="tooltip-inverse"
                    data-bs-placement="top"
                    title={t('articles.invoice_text_title')}
                    className='btn btn-sm p-2'
                  >
                    <QuestionMark />
                  </span>
                  <Field
                    name="billName"
                    component="input"
                    type="text"
                    className="form-control"
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col">
                  <label className='required'>{t('shared.product_group')}</label>
                  <Field
                    name='articleGroupId'
                    component={AsyncSelectAdapter}
                    loadOptions={loadArticleGroupOptions}
                    placeholder="Select"
                    isClearable={true}
                  >
                  </Field>
                  <ErrorField name="articleGroupId" />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col">
                  <label className='required'>{t('shared.unit')}</label>
                  <Field
                    name='unitId'
                    component={AsyncSelectAdapter}
                    loadOptions={loadUnitOptions}
                    placeholder="Select"
                    isClearable={true}
                  >
                  </Field>
                  <ErrorField name="unitId" />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col">
                  <label className='required'>{t('shared.revenue_code')}</label>
                  <Field
                    name='revenueCodeId'
                    component={AsyncSelectAdapter}
                    loadOptions={loadRevenueCodeOptions}
                    placeholder="Select"
                    isClearable={true}
                  >
                  </Field>
                  <ErrorField name="revenueCodeId" />
                </div>
              </div>
              {submitError && <div className="form-error-message">{submitError}</div>}
              <div className="modal-footer">
                <TableButton
                  className="btn btn-light btn-active-light-primary fw-semibold me-2 px-6"
                  type="button"
                  onClick={onClose}
                  title={t('cancel')}
                />
                <TableButton
                  className="btn btn-primary fw-semibold px-6"
                  type="submit"
                  disabled={submitting || pristine}
                  title={t('create')}
                />
              </div>
            </div>
          </>
        </form>
      )}
    />
  )
}

export default Create;
