import React, { createElement } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const TableButton = (props) => {
  const { size, icon, title, className, ...other} = props
  const CustomTag = props.href ? 'a' : 'button'

  return (
    <CustomTag className={classNames('btn', {'btn-sm': size === 'small'}, className)} {...other}>
      {icon}
      {title}
    </CustomTag>
  )
}

TableButton.propTypes = {
  size: PropTypes.oneOf(['small', null]),
  icon: PropTypes.element,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
};
export default TableButton
