import React, {useState, useEffect, useCallback, useRef} from 'react';
import CustomTimeline from "../../components/Disposition/CustomTimeline";
import "react-calendar-timeline/lib/Timeline.css";
import './index.css'
import CreateOrderModal from "../../components/Disposition/CreateOrderModal";
import Header from "../../components/Disposition/Header";
import OrderSidebar from "../../components/Disposition/OrderSidebar";
import DndProviderWrapper from "../../core/DndProvider";
import StaffSidebar from "../../components/Disposition/StaffSidebar";
import {axiosInstance, routes} from "../../utils/api_base";
import moment from 'moment'

function Disposition() {
    const defaultTimeStart = moment()
        .startOf("day")
        .add(-100, "day")
        .toDate();
    const defaultTimeEnd = moment()
        .startOf("day")
        .add(-90, "day")
        .toDate();



    const [open, setOpen] = useState(false)
    const [viewType, setViewType] = useState('resource')
    const [timeType, setTimeType] = useState('week')
    const [state, setState] = useState({groups: [], items: [], groupIndex: null, time: null})
    const [loading, setLoading] = useState(true)
    const [units, setUnits] = useState([])
    const [orders, setOrders] = useState([])
    const [services, setServices] = useState([])
    const [businessPartners, setBusinessPartners] = useState([])
    const [staffs, setStaffs] = useState([])
    const [time, setTime] = useState({startTime: defaultTimeStart, endTime: defaultTimeEnd })

    const getStaffs = async (pagination) => {
        const {pageIndex, pageSize} = pagination
        const params = {
            page: pageIndex + 1,
            limit: pageSize,
            active: true,
            without_admin_and_farmer: true,
            show_in_dispo: true
        }
        const res = await axiosInstance.get(
            routes.staffs(),
            {
                params: params
            }
        )

        return (
            {rows: res.data.data, pageCount: res.data.meta.total_pages, rowCount: res.data.meta.total_count}
        )
    }

    const getUnits = async (pagination, sorting) => {
        const {pageIndex, pageSize} = pagination
        const params = {page: pageIndex + 1, limit: pageSize, base: true}
        const res = await axiosInstance.get(
            routes.units(),
            {
                params: params
            }
        )

        return (
            {rows: res.data.data, pageCount: res.data.meta.total_pages, rowCount: res.data.meta.total_count}
        )
    }

    const getServices = async (pagination, sorting) => {
        const {pageIndex, pageSize} = pagination
        const params = {page: pageIndex + 1, limit: pageSize, active: true, serialize: 'short'}
        const res = await axiosInstance.get(
            routes.services(),
            {
                params: params
            }
        )

        return (
            {rows: res.data.data, pageCount: res.data.meta.total_pages, rowCount: res.data.meta.total_count}
        )
    }

    const getBusinessPartners = async (pagination, sorting) => {
        const {pageIndex, pageSize} = pagination
        const params = {page: pageIndex + 1, limit: pageSize, active: true}
        const res = await axiosInstance.get(
            routes.businessPartners(),
            {
                params: params
            }
        )

        return (
            {rows: res.data.data, pageCount: res.data.meta.total_pages, rowCount: res.data.meta.total_count}
        )
    }

    useEffect(() => {
        getOrders({
            pageIndex: 0,
            pageSize: 100000
        }, undefined, defaultTimeStart, defaultTimeEnd).then((result) => setOrders(result.rows))
        getStaffs({pageIndex: 0, pageSize: 100000}).then((result) => setStaffs(result.rows))
    }, []);


    const timeRef = useRef(undefined)

    useEffect(() => {
        if(timeRef.current) clearTimeout(timeRef.current)

        timeRef.current = setTimeout(() => {
            const startTime = moment(time.startTime).toDate()
            const endTime = moment(time.endTime).toDate()

            getOrders({
                pageIndex: 0,
                pageSize: 100000
            }, undefined, startTime, endTime).then((result) => setOrders(orders => {
                const newOrders = [...orders, ...result.rows];

                const uniqueOrders = newOrders.reduce((acc, order) => {
                    acc[order.id] = order;
                    return acc;
                }, {});

                return Object.values(uniqueOrders);
            }))

        }, 1000)

    }, [time]);


    useEffect(() => {
        if(!open) return

        getServices({pageIndex: 0, pageSize: 100000}).then((result) => setServices(result.rows))
        getBusinessPartners({pageIndex: 0, pageSize: 100000}).then((result) => setBusinessPartners(result.rows))
        getUnits({pageIndex: 0, pageSize: 100000}, {}).then((result) => setUnits(result.rows))
    }, [open])


    const getOrders = async (pagination, sorting, startTime, endTime) => {
        const {pageIndex, pageSize} = pagination
        const params = {
            page: pageIndex + 1,
            limit: pageSize,
            start_time: startTime,
            end_time: endTime,
            vague_date: "false"
        }
        const res = await axiosInstance.get(
            routes.orders(),
            {
                params: params
            }
        )

        return (
            {rows: res.data.data, pageCount: res.data.meta.total_pages, rowCount: res.data.meta.total_count}
        )
    }


    const buildStaffGroups = useCallback((staffs) => {
        return staffs.map(staff => ({
            id: staff.id + '',
            title: staff.attributes.long_name,
            rightTitle: '',
            bgColor: ''
        }))
    }, [])

    const buildOrderGroups = useCallback((orders) => {
        return orders.map(order => ({
            id: order.id + '',
            title: order.id + '',
            rightTitle: '',
            bgColor: ''
        }))
    }, [])

    const buildStaffOrderItems = useCallback((orders) => {
        let items = []

        orders.map(order => {
            const startDate = order.attributes.start_time
            const endDate = order.attributes.end_time
            const startValue = Math.floor(moment(startDate).valueOf() / 10000000) * 10000000
            const endValue = Math.floor(moment(endDate).valueOf() / 10000000) * 10000000

            order.attributes.staff_orders.map(staffOrder => {
                items.push({
                    id: staffOrder.id,
                    group: staffOrder.attributes.staff_id + '',
                    title: staffOrder.attributes.name_without_comment,
                    start: startValue,
                    end: endValue,
                    status: staffOrder.attributes.status_code,
                    bgColor: staffOrder.attributes.service?.attributes?.service_color || '#ddd',
                    // canMove: startValue > new Date().getTime(),
                    // canResize: startValue > new Date().getTime() ? (endValue > new Date().getTime() ? 'both' : 'left') : (endValue > new Date().getTime() ? 'right' : false),
                    className: (moment(startDate).day() === 6 || moment(startDate).day() === 0) ? 'item-weekend' : '',
                    itemProps: {
                        'data-tip': order.attributes.business_partner_name
                    }
                })
            })
        })

        return items
    }, [])

    const buildOrderItems = useCallback((orders) => {
        let items = []

        orders.map(order => {
            const startDate = order.attributes.start_time
            const endDate = order.attributes.end_time
            const startValue = Math.floor(moment(startDate).valueOf() / 10000000) * 10000000
            const endValue = Math.floor(moment(endDate).valueOf() / 10000000) * 10000000

            items.push({
                id: order.id,
                group: order.id + '',
                title: order.attributes.business_partner_name,
                start: startValue,
                end: endValue,
                status: order.attributes.status_code,
                bgColor: '#dddddd',
                // canMove: startValue > new Date().getTime(),
                // canResize: startValue > new Date().getTime() ? (endValue > new Date().getTime() ? 'both' : 'left') : (endValue > new Date().getTime() ? 'right' : false),
                className: (moment(startDate).day() === 6 || moment(startDate).day() === 0) ? 'item-weekend' : '',
                itemProps: {
                    'data-tip': order.attributes.business_partner_name
                }
            })
        })

        return items
    }, [])



    useEffect(() => {
        if(staffs.length === 0) return


        if (viewType === 'orders') {
            const filteredOrders = orders.filter(order => {
                const startDate = new Date(order.attributes.start_time);
                const endDate = new Date(order.attributes.end_time);

                return startDate >= new Date(time.startTime) && endDate <= new Date(time.endTime);
            });

            const groups = buildOrderGroups(filteredOrders);
            const items = buildOrderItems(filteredOrders)


            if(groups.length === 0)
                groups.push({
                    id: '0',
                    title: '',
                })


            setState((state) => ({ ...state, items, groups}))

        } else {
            const groups = buildStaffGroups(staffs);
            const items = buildStaffOrderItems(orders)
            setState((state) => ({...state, items, groups}))
        }
    }, [viewType, staffs, orders]);


    const sidebarWidth = 300

    const width = window.innerWidth - 320 - sidebarWidth
    const height = window.innerHeight - 200
    return (<div style={{marginTop: '-1.5rem', height: 'calc(100% + 3rem)', width}}>
            <Header onSelectViewType={setViewType}
                    viewType={viewType}
                    startTime={time.startTime}
                    endTime={time.endTime}
                    type={timeType}
                    addOrder={() => setOpen(true)}
                    onChangeType={setTimeType}/>

            <DndProviderWrapper>
                <div className={"card shadow-md"} style={{width, height, overflowY: 'scroll'}}>
                    {state.groups.length > 0 && <CustomTimeline onAdd={(groupIndex, time) => {
                        setState((state) => ({...state, staffId: groupIndex, time}))
                        setOpen(true)
                    }}
                                                                onDrop={console.log}
                                                                groups={state.groups}
                                                                defaultTimeStart={defaultTimeStart}
                                                                defaultTimeEnd={defaultTimeEnd}
                                                                onTimeChange={(startTime, endTime) => setTime({
                                                                    startTime,
                                                                    endTime
                                                                })}
                                                                items={state.items}/>}
                </div>

                {viewType === 'orders' ? <StaffSidebar/> : <OrderSidebar/>}
            </DndProviderWrapper>

            {open && <CreateOrderModal open={open}
                                       onClose={() => setOpen(false)}
                                       services={services}
                                       businessPartners={businessPartners}
                                       units={units}
                                       staffs={staffs}
                                       staffId={state.staffId}
                                       time={state.time}/>}
        </div>
    );
}

export default Disposition;
