import React, { useMemo, useState } from 'react';
import { axiosInstance, routes } from '../../utils/api_base';
import { useTranslation } from 'react-i18next';
import { pushToHistory } from '../../utils/base';
import BaseTable from '../Table';
import TableButton from '../Table/TableButton';
import { ExportIcon, PlusIcon, FilterIcon } from '../Icons';
import { isEmpty } from 'lodash';
import Create from './Create';
import Modal from '../Modal';

const Services = ({filters}) => {
  const { t, i18n } = useTranslation()
  const [modalOpen, setModalOpen] = useState(false)

  const getServices = async (pagination, sorting, globalFilter, customFilters) => {
    const { pageIndex, pageSize } = pagination
    const sortQuery = sorting.map((attr) => {
      return attr.desc ? `${attr.id} desc` : attr.id
    })
    let params = { page: pageIndex + 1, limit: pageSize, sort: sortQuery[0] }
    if (globalFilter) {
      params.query = globalFilter
    }
    if (!isEmpty(customFilters)) {
      params = {...params, ...customFilters}
    }
    const res = await axiosInstance.get(routes.newServices(), { params: params })
    pushToHistory(params)

    return(
      { rows: res.data.data, pageCount: res.data.meta.total_pages, rowCount: res.data.meta.total_count }
    )
  }

  const tableToolbar = () => {
    return(
      <>
        <TableButton type="button" className="btn-light-primary me-3" icon={<ExportIcon />} title={t('shared.export')} />
        <TableButton
          className="btn-primary"
          onClick={() => setModalOpen(true)}
          icon={<PlusIcon />}
          title={t('shared.add_to')}
        />
      </>
    )
  }

  const codeHtml = (row) => {
    return(
      <TableButton
        target="_blank"
        href={row?.original?.attributes?.link}
        className="btn-light-primary me-3"
        title={row?.original?.attributes?.client_specific_number}
      />
    )
  }

  const columns = useMemo(
    () => [
      {
        id: 'code',
        accessorFn: row => row?.attributes?.client_specific_number,
        header: t('shared.code'),
        size: 70,
        minSize: 70,
        cell: ({ row }) => {
          return codeHtml(row)
        },
      },
      {
        id: 'name',
        accessorFn: row => row?.attributes?.name,
        header: t('shared.performance'),
        cell: row => row.getValue(),
      },
      {
        id: 'group',
        accessorFn: row => row?.attributes?.service_group_name,
        header: t('shared.group'),
        cell: row => row.getValue(),
      },
      {
        id: 'quantity',
        accessorFn: row => row?.attributes?.service_quantity,
        header: t('shared.quantity'),
        cell: row => row.getValue(),
      },
      {
        id: 'sales_volume',
        accessorFn: row => row?.attributes?.revenue,
        header: t('shared.sales_volume'),
        cell: row => row.getValue(),
      },
      {
        id: 'costs',
        accessorFn: row => row?.attributes?.costs,
        header: t('shared.costs'),
        cell: row => row.getValue(),
      },
      {
        id: 'profit_loss',
        accessorFn: row => row?.attributes?.profit,
        header: t('shared.profit_loss'),
        cell: row => row.getValue(),
      },
    ],
    [i18n.language]
  )

  return (
    <>
      <Modal className="modal fade show" open={modalOpen} onClose={() => setModalOpen(false)} title={t('shared.new_service')}>
        <Create machineGroups={1} onClose={() => setModalOpen(false)} />
      </Modal>
      <BaseTable
        columns={columns}
        columnVisibility={{}}
        getDataFn={getServices}
        tableToolbar={tableToolbar}
        filters={filters}
      />
    </>
  )
}

export default Services
