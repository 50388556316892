import React from "react";

import classNames from 'classnames'

const List = ({ children, className, style }) => {

    return <ul className={classNames("list-group")} style={style || {}}>
        { children }
    </ul>
}

export default List
