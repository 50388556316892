import React from "react";

const ArrowRightBase = ({ className = null }) => {
  return <i className={`ki-duotone ki-arrow-right ${className}`}>
    <span className="path1"/>
    <span className="path2"/>
  </i>
}

export default ArrowRightBase;
