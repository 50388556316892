import React from 'react';
import Table from './Table';

const ServiceFeatureChronicles = ({ service_id }) => {

  const tableToolbar = () => {
    return (
      <>
      </>
    )
  }

  return (
    <>
      <Table service_id={service_id} tableToolbar={tableToolbar} />
    </>
  )
}

export default ServiceFeatureChronicles;
