// extracted by mini-css-extract-plugin
var _1 = "VTYr5dec54TvNj8VTgN0";
var _2 = "ICi3NoCdsfgcqTzEXvQg";
var _3 = "uAu_3WsAgrIfGjVLXoTy";
var _4 = "zIFBVUhr6fOUMwZfBpNR";
var _5 = "JCQnLI0GdypmRvxcvOuz";
var _6 = "LhZS4FcYPHNWp3_U8VDU";
var _7 = "yFAhUNW2LruNK2F4_FcA";
var _8 = "rAa1QhnXyx_6NVJnxjM4";
var _9 = "E7qebn9ETqW4ug5jXstr";
var _a = "pCxYOp0XoosVcob9hBKg";
var _b = "xImi5nz4XNOu2fjzrzgE";
var _c = "lq7zNIvEdacrkjQ51e41";
var _d = "Dki8DtbqrR19URsvZWIg";
export { _1 as "button", _2 as "danger", _3 as "dark", _4 as "info", _5 as "light", _6 as "loading", _7 as "loadingText", _8 as "primary", _9 as "secondary", _a as "spin", _b as "spinner", _c as "success", _d as "terciary" }
