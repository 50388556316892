import React from "react";
import PropTypes from 'prop-types';

const Textarea = ({ label, defaultValue, onChange }) => {

    return (
        <>
            { label && <label className="fs-5 fw-semibold mb-2">{label}</label> }
            <textarea className="form-control" data-kt-autosize="true" defaultValue={defaultValue} onBlur={event => onChange(event.target.value)}></textarea>
        </>
    )
}

Textarea.propTypes = {
    label: PropTypes.string,
    defaultValue: PropTypes.string
};

export default Textarea
