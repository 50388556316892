import React, { useState, useEffect, cloneElement } from 'react'
import PropTypes from 'prop-types';

const DebounceInput = (props) => {
  const { initialValue = '', onChange, debounce = 1000 } = props
  const [value, setValue] = useState(initialValue)

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounce)

    return () => clearTimeout(timeout)
  }, [value])

    const renderChildren = () => {
      return cloneElement(props.children, {
        value: value, onChange: (e) => setValue(e.target.value)
      })
    }

  return (<>{renderChildren()}</>)
}

DebounceInput.propTypes = {
  initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  debounce: PropTypes.number,
  children: PropTypes.element.isRequired
};
export default DebounceInput
