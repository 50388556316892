import React, { useState } from 'react';
import TableButton from '../Table/TableButton';
import { useTranslation } from 'react-i18next';

const ExpandableList = ({ items, limit = 3, renderItem }) => {
  const { t } = useTranslation()
  const [isExpanded, setIsExpanded] = useState(false)
  const toggleExpanded = () => setIsExpanded(!isExpanded)

  const visibleItems = isExpanded ? items : items.slice(0, limit)

  return (
    <div>
      {visibleItems.map((item, index) => (
        <div key={index}>
          {renderItem(item, index)}
        </div>
      ))}
      {items.length > limit && !isExpanded && (
        <TableButton
          size="small"
          title={t('show_more', { number: (items.length - limit) })}
          onClick={toggleExpanded}
          style={{ marginLeft: 'auto', marginRight: 0, display: 'block' }}
        />
      )}
      {isExpanded && (
        <TableButton
          size="small"
          title={t('show_less')}
          onClick={toggleExpanded}
          style={{ marginLeft: 'auto', marginRight: 0, display: 'block' }}
        />
      )}
    </div>
  );
};

export default ExpandableList;
