import React, { useState }from 'react';
import { useTranslation } from 'react-i18next';
import TableButton from '../../components/Table/TableButton';
import { ExportIcon } from '../../components/Icons';
import Table from './Table';

const StaffOvertime = ({ filters, months}) => {
  const { t } = useTranslation()
  const [exportUrl, setExportUrl] = useState('/staffs/working_time.xls');
  const [pageTitle, setPageTitle] = useState(t('staffs.staff_times'));

  const handleApplyFilter = (params) => {
    const baseExportUrl = '/staffs/working_time.xls'
    const queryString = createQueryString(params);
    const nowDate = new Date()
    const defMonth = nowDate.getMonth();
    const defYear = nowDate.getFullYear()

    setPageTitle(`${t('staffs.staff_times')} ${months[(params.month - 1) || defMonth]} ${params.year || defYear}`)
    setExportUrl(`${baseExportUrl}?${queryString}`);
  };

  const createQueryString = (params) => {
    return Object.keys(params)
      .map(key => {
        const value = params[key];
        if (Array.isArray(value)) {
          return value.map(v => `${encodeURIComponent(key)}[]=${encodeURIComponent(v)}`).join('&');
        } else {
          return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
        }
      })
      .join('&');
  };

  const tableToolbar = () => {
    return (
      <>
        <TableButton 
          type="button"
          target="_blank"
          href={exportUrl}
          className="btn-light-primary me-3"
          icon={<ExportIcon />}
          title={t('shared.export')} />
      </>
    )
  }
  
  return (
    <>
      <div className='fs-5 pb-4'>
        {pageTitle}
      </div>
      <Table filters={filters} tableToolbar={tableToolbar} onApplyFilter={handleApplyFilter}/>
    </>
  )
}

export default StaffOvertime;
