import React, {useState} from "react";
import { axiosInstance, routes } from '../../utils/api_base';
import Toastr from "../Toastr";
import DateRangePicker from "../DateRangePicker";
import { useTranslation } from 'react-i18next';

const SelectPeriod = ({ start_date_label, end_date_label, staff_customizing_id }) => {
  const { t, i18n } = useTranslation()
  const [dateRange, setDateRange] = useState({ startDate: start_date_label, endDate: end_date_label });

  const handleDateChange = (newStartDate, newEndDate) => {
    return saveRange(newStartDate.format('DD/MM/YYYY'), newEndDate.format('DD/MM/YYYY'))
  }

  const saveRange = async (startDate, endDate) => {
    return await axiosInstance
      .patch(
        routes.updateDateRange(staff_customizing_id),
        {
          staff_customizing: {
            current_start_date: startDate,
            current_end_date: endDate
          },
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      .then(response => {
        setDateRange({
          startDate: startDate,
          endDate: endDate,
        })

        Toastr({
          title: t('shared.success'),
          message: t('shared.date_changed'),
          options: { closeButton: true }
        })

        return
      })
      .catch(error => {
        let errorMessage = error.response.data;

        Toastr({
          type: 'error',
          message: errorMessage,
          options: { closeButton: true }
        })

        raise
      })
  }

  return (
    <DateRangePicker
      placeholder='Select Range'
      language={i18n.language}
      classList="bg-transparent border-0 cursor-pointer form-control form-control-sm form-control-solid text-center"
      options={{
        showDropdowns: true,
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
        onChange: handleDateChange,
        autoApply: false
      }}
    />
  );
};

export default SelectPeriod
