import React from 'react';
import Table from './Table';
import { useTranslation } from 'react-i18next';

const MachineGroupStatistics = ({ filters }) => {
  const { t } = useTranslation()

  const tableToolbar = () => {
    return (<></>)
  }

  return (
    <Table filters={filters} tableToolbar={tableToolbar} />
  )
}

export default MachineGroupStatistics;
