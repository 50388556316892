import React from "react";
import * as styles from './OrderItem.module.scss'

const itemRenderer = ({item, itemContext, getItemProps, getResizeProps}) => {
    const {left: leftResizeProps, right: rightResizeProps} = getResizeProps();

    const statusToColor = () => {
        switch (item.status){
            case 'PENDING':
                return 'var(--bs-success)';
            case 'RELEASED':
                return 'var(--bs-danger)';
            case 'INPROCESS':
                return 'var(--bs-warning)';
            default:
                return 'var(--bs-gray-500)';
        }
    }

    const backgroundColor = itemContext.selected ? (itemContext.dragging ? "red" : item.selectedBgColor) : `${item.bgColor}95`;
    return (
        <div
            {...getItemProps({
                style: {
                    backgroundColor,
                    color: item.color,
                    borderLeftWidth: itemContext.selected ? 3 : 1,
                    borderRightWidth: itemContext.selected ? 3 : 1,
                    borderWidth: 0,
                    display: 'inline-block',
                    fontSize: '.75em',
                    fontWeight: 700,
                    lineHeight: 1,
                    whiteSpace: 'nowrap',
                    verticalAlign: 'baseline',
                    borderRadius: '.25rem',
                },

                onMouseDown: () => {
                    console.log("on item click", item);
                }
            })}>
            {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : null}
            <span className={styles.badge} style={{ backgroundColor: statusToColor() }}></span>

            <div
                style={{
                    height: itemContext.dimensions.height,
                    overflow: "hidden",
                    paddingLeft: '2.5em',
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap"
                }}
            >
                {itemContext.title}
            </div>

            {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : null}
        </div>
    );
};

export default itemRenderer
