import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import flatpickr from "flatpickr";
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect';
import 'flatpickr/dist/plugins/monthSelect/style.css';
import { German } from "flatpickr/dist/l10n/de.js"
import { Polish } from "flatpickr/dist/l10n/pl.js"
import { useTranslation } from 'react-i18next';

const DatePicker = ({ placeholder, className = "", monthOnly = false, options }) => {
  const { i18n } = useTranslation()
  const flatpickrInput = useRef(null);

  if (monthOnly) {
    options['plugins'] = [new monthSelectPlugin({
      shorthand: options['shorthandCurrentMonth'],
      dateFormat: options['dateFormat']
    })]
  }

  if (i18n.language === "de") {
    options['locale'] = German
  } else if (i18n.language === "pl") {
    options['locale'] = Polish
  }

  useEffect(() => {
    flatpickr(flatpickrInput.current, options);
  }, [])

  return (
    <input className={`form-control form-control-solid ${className}`} placeholder={placeholder} ref={flatpickrInput}/>
  )
}

DatePicker.defaultProps = {
  options: {
    allowInput: false,
    allowInvalidPreload: false,
    altFormat: "F j, Y",
    altInput: false,
    altInputClass: "",
    ariaDateFormat: "F j, Y",
    clickOpens: true,
    dateFormat: "Y-m-d",
    defaultHour: 12,
    defaultMinute: 0,
    disable: [],
    disableMobile: false,
    enableSeconds: false,
    enableTime: false,
    hourIncrement: 1,
    inline: false,
    minuteIncrement: 5,
    mode: "single",
    monthSelectorType: "dropdown",
    nextArrow: ">",
    noCalendar: false,
    position: "auto",
    prevArrow: "<",
    shorthandCurrentMonth: false,
    showMonths: 1,
    static: false,
    time_24hr: false,
    weekNumbers: false,
    wrap: false
  }
}

DatePicker.propTypes = {
  placeholder: PropTypes.string,
  options: PropTypes.shape({
    allowInput: PropTypes.bool,
    allowInvalidPreload: PropTypes.bool,
    altFormat: PropTypes.string,
    altInput: PropTypes.bool,
    altInputClass: PropTypes.string,
    appendTo: PropTypes.element,
    ariaDateFormat: PropTypes.string,
    clickOpens: PropTypes.bool,
    conjunction: PropTypes.string,
    dateFormat: PropTypes.string,
    defaultDate: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.arrayOf(PropTypes.object)
    ]),
    defaultHour: PropTypes.number,
    defaultMinute: PropTypes.number,
    disable: PropTypes.array,
    disableMobile: PropTypes.bool,
    enable: PropTypes.array,
    enableSeconds: PropTypes.bool,
    enableTime: PropTypes.bool,
    formatDate: PropTypes.func,
    hourIncrement: PropTypes.number,
    inline: PropTypes.bool,
    maxDate: PropTypes.string,
    minDate: PropTypes.string,
    minuteIncrement: PropTypes.number,
    mode: PropTypes.oneOf(['single', 'multiple', 'range']),
    monthSelectorType: PropTypes.string,
    nextArrow: PropTypes.string,
    noCalendar: PropTypes.bool,
    onChange: PropTypes.func,
    onClose: PropTypes.func,
    onOpen: PropTypes.func,
    onReady: PropTypes.func,
    parseDate: PropTypes.func,
    position: PropTypes.string,
    positionElement: PropTypes.element,
    prevArrow: PropTypes.string,
    shorthandCurrentMonth: PropTypes.bool,
    showMonths: PropTypes.number,
    static: PropTypes.bool,
    time_24hr: PropTypes.bool,
    weekNumbers: PropTypes.bool,
    wrap: PropTypes.bool
  })
}

export default DatePicker;
