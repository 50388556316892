import React from 'react';
import Button from '../Button';
import * as style from './index.module.scss';

const ModalFooter = ({ children }) => {

  return (
    <div className={`${style.footer}`}>
      <div className="mt-4">
          { children }
      </div>
    </div>
  )
}

export default ModalFooter;
