import React, { useMemo } from 'react';
import BaseTable from '../Table';
import {isEmpty} from 'lodash';
import {axiosInstance, routes} from '../../utils/api_base';
import {pushToHistory} from '../../utils/base';
import { useTranslation } from 'react-i18next';
import TableButton from '../Table/TableButton';

const Table = ({tableToolbar, columnVisibility, filters}) => {
  const { t, i18n } = useTranslation()

  const getStaffs = async (pagination, sorting, globalFilter, customFilters) => {
    const { pageIndex, pageSize } = pagination
    const sortQuery = sorting.map((attr) => {
      return attr.desc ? `${attr.id} desc` : attr.id
    })
    let params = { page: pageIndex + 1, limit: pageSize, sort: sortQuery[0] }
    if (globalFilter) {
      params.query = globalFilter
    }
    if (!isEmpty(customFilters)) {
      params = {...params, ...customFilters}
    }
    const res = await axiosInstance.get(routes.staffs(), { params: {...params, serialize: "full"} })
    pushToHistory(params)

    return(
      { rows: res.data.data, pageCount: res.data.meta.total_pages, rowCount: res.data.meta.total_count }
    )
  }


  const codeHtml = (row) => {
    return(
      <TableButton
        target="_blank"
        href={row?.original?.attributes?.link}
        className="btn-light-primary me-3"
        title={row?.original?.attributes?.client_specific_number}
      />
    )
  }

  const columns = useMemo(
    () => [
      {
        id: 'code',
        header: t('shared.number'),
        accessorFn: row => row?.attributes?.client_specific_number,
        size: 70,
        minSize: 70,
        enableSorting: true,
        cell: ({ row }) => {
          return codeHtml(row)
        },
      },
      {
        id: 'first_name',
        accessorFn: row => row?.attributes?.user_first_name,
        header: t('shared.first_name'),
        cell: row => row.getValue(),
      },
      {
        id: 'last_name',
        accessorFn: row => row?.attributes?.user_last_name,
        header: t('shared.last_name'),
        cell: row => row.getValue(),
      },
      {
        id: 'mobile_company',
        accessorFn: row => row?.attributes?.user_mobile_company,
        header: t('staffs.mobile_number'),
        cell: row => row.getValue(),
      },
      {
        id: 'work_company',
        accessorFn: row => row?.attributes?.user_fon_company,
        header: t('staffs.landline'),
        cell: row => row.getValue(),
      },
      {
        id: 'working_time',
        accessorFn: row => row?.attributes?.working_time,
        header: t('staffs.working_hours_per_month'),
        cell: row => row.getValue(),
      },
      {
        id: 'work_account',
        accessorFn: row => row?.attributes?.work_account,
        header: t('staffs.working_time_account'),
        cell: row => row.getValue(),
      },
      {
        id: 'location',
        accessorFn: row => row?.attributes?.location_name,
        header: t('shared.location'),
        cell: row => row.getValue(),
      },
    ],
    [i18n.language]
  )

  return (
    <BaseTable
      getDataFn={getStaffs}
      columns={columns}
      columnVisibility={columnVisibility}
      tableToolbar={tableToolbar}
      filters={filters}
      initialSortDirection={"asc"}
    />
  )
}

export default Table;
