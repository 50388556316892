import React from 'react'
import { Translation } from 'react-i18next';

export const isRequired = value => {
  return(value ? undefined : <Translation>{ (t) => t('validations.required')}</Translation>)
}

export const minLength = min => value => {
  if (!(value instanceof String || value.length >= min)) {
    return <Translation>{ (t) =>`${t('validations.min_length')} ${min}`}</Translation>
  }
}

export const composeValidators = (...validators) => value =>
  validators.reduce((error, validator) => error || validator(value), undefined)
