import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Toastr from '../Toastr';
import { axiosInstance, routes } from '../../utils/api_base';
import { Form, Field } from 'react-final-form'
import TableButton from '../Table/TableButton';

const Create = ({onClose}) => {
  const { t } = useTranslation();
  const [errors, setErrors] = useState([]);

  const onSubmit = async values => {
    const data = {
      unit: {
        name: values.name,
        short: values.short,
        ecode: values.ecode
      }
    };

    axiosInstance.post(routes.units(), data)
      .then(response => {
        Toastr({
          title: t('shared.success'),
          message: response.data.message,
          options: { closeButton: true }
        })

        onClose()
      })
      .catch(error => {
        Toastr({
          type: 'error',
          message: 'Error!'
        })
        setErrors(error.response.data.error);
      })
  }

  const invalidInput = (message) => {
    return (
      <div className="invalid-feedback">
        {message}
      </div>
    )
  };

  return(
    <Form
      onSubmit={onSubmit}
      initialValues={{
        name: '',
        short: '',
        ecode: ''
      }}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <div className="container">
            <div className="row mb-3">
              <div className="col">
                <label className='required'>{t('shared.name')}</label>
                <Field
                  name="name"
                  component="input"
                  type="text"
                  required="required"
                  className={`form-control ${errors["name"] ? "is-invalid" : ""}`}
                />
                {errors["name"] ? invalidInput(errors["name"]) : null}
              </div>
            </div>
            <div className="row mb-3">
              <div className="col">
                <label className='required'>{t('shared.short_name')}</label>
                <Field
                  name="short"
                  component="input"
                  type="text"
                  required="required"
                  className={`form-control ${errors["short"] ? "is-invalid" : ""}`}
                />
                {errors["short"] ? invalidInput(errors["short"]) : null}
              </div>
            </div>
            <div className="row mb-3">
              <div className="col">
                <label>{t('shared.einvoice_code')}</label>
                <Field
                  name="ecode"
                  component="input"
                  type="text"
                  className={`form-control ${errors["ecode"] ? "is-invalid" : ""}`}
                />
                {errors["ecode"] ? invalidInput(errors["ecode"]) : null}
              </div>
            </div>
            <div className="modal-footer">
              <TableButton
                className="btn btn-light btn-active-light-primary fw-semibold me-2 px-6"
                type="button"
                onClick={onClose}
                title={t('cancel')}
              />
              <TableButton
                className="btn btn-primary fw-semibold px-6"
                type="submit"
                title={t('create')}
              />
            </div>
          </div>
        </form>
      )}
    />
  )
}

export default Create;
