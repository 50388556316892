import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { axiosInstance, routes } from '../../utils/api_base';
import Toastr from "../Toastr";

const MakeMeHappy = ({email, i18n}) => {

  const changeLanguage = (locale) => {
    axiosInstance
      .put(
        routes.updateLocale(),
        { locale: locale }
      )
      .then(response => {
        console.log('RESPONSE', response)
        Toastr({
          title: 'Success!',
          message: 'The language changed successfully',
          options: { closeButton: true }
        })
        i18n.changeLanguage(locale)
      })
      .catch(error => {
        console.log('error', error)
        Toastr({
          type: 'error',
          message: 'The language was not changed'
        })
      })
  }

  return (
    <div>
      <h3>{i18n.t('greetings')}, {email}!</h3>
      <hr />
      <button type="button" onClick={() => changeLanguage('de')}>
        de
      </button>
      <button type="button" onClick={() => changeLanguage('en')}>
        en
      </button>
      <button type="button" onClick={() => changeLanguage('pl')}>
        pl
      </button>
    </div>
  );
};

MakeMeHappy.propTypes = {
  email: PropTypes.string.isRequired, // this is passed from the Rails view
};

export default withTranslation()(MakeMeHappy)
