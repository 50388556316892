import React, { useMemo } from 'react';
import BaseTable from '../../components/Table';
import { isEmpty } from 'lodash';
import { axiosInstance, routes } from '../../utils/api_base';
import { pushToHistory } from '../../utils/base';
import { useTranslation } from 'react-i18next';
import StatusLabel from '../../core/StatusLabel';

const Table = ({ tableToolbar, filters }) => {
  const { t, i18n } = useTranslation();

  const getPurchaseBills = async (pagination, sorting, globalFilter, customFilters) => {
    const { pageIndex, pageSize } = pagination
    const sortQuery = sorting.map((attr) => {
      return attr.desc ? `${attr.id} desc` : attr.id
    })
    let params = { page: pageIndex + 1, limit: pageSize, sort: sortQuery[0] }

    if (globalFilter) {
      params.query = globalFilter
    }
    if (!isEmpty(customFilters)) {
      params = { ...params, ...customFilters }
    }

    const res = await axiosInstance.get(routes.purchaseBills(), { params: params })
    pushToHistory(params)

    return (
      { rows: res.data.data, pageCount: res.data.meta.total_pages, rowCount: res.data.meta.total_count }
    )
  }

  const columns = useMemo(
    () => [
      {
        id: 'invoice',
        header: t('invoice'),
        enableSorting: true,
        cell: ({ row }) => {
          const attributes = row?.original?.attributes
          return <a key={attributes.id} href={attributes.purchase_bill_link}>{attributes.name}</a>
        },
      },
      {
        id: 'date',
        header: t('date'),
        accessorFn: row => row?.attributes?.date,
        enableSorting: true,
        cell: row => row.getValue(),
      },
      {
        id: 'customer',
        header: t('customer'),
        accessorFn: row => row?.attributes?.customer,
        enableSorting: false,
        cell: row => row.getValue(),
      },
      {
        id: 'amount',
        header: t('amount'),
        accessorFn: row => row?.attributes?.amount,
        enableSorting: true,
        cell: row => row.getValue(),
      },
      {
        id: 'status',
        header: t('status'),
        cell: ({ row }) => {
          return <StatusLabel code={row.original.attributes?.status_code?.toLowerCase()} />
        },
      },
    ],
    [i18n.language]
  );

  return (
    <BaseTable
      getDataFn={getPurchaseBills}
      columns={columns}
      tableToolbar={tableToolbar}
      filters={filters}
    />
  );
}

export default Table;
