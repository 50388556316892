function importAll(r) {
  r.keys().forEach(r);
}

importAll(require.context('./layout/', true, /\.js$/));
importAll(require.context('./components/', true, /\.js$/));
// importAll(require.context('./vendors/', true, /\.js$/));
importAll(require.context('./widgets/', true, /\.js$/));
// importAll(require.context('./custom/', true, /\.js$/));


// Components
import './components/app'
import './components/util'
import './components/drawer'
import './components/menu'
import './components/scroll'
import './components/sticky'
import './components/swapper'
import './components/toggle'
import './components/scrolltop'
import './components/dialer'
import './components/image-input'
import './components/password-meter'
import './components/event-handler'

//Layout
import './layout/theme-mode'
