import React from "react";
import Button from "../../components/Button";

import classNames from 'classnames'

import {AddIcon} from '../../components/Icons'
import TimeSwitch from "./TimeSwitch";
import TypeSwitch from "./TypeSwitch";
import HeaderTitle from "./HeaderTitle";

const Header = ({addOrder, onSelectViewType, viewType, onClickPrev, onClickNext, type, onChangeType, onClickToday, startTime, endTime}) => {

    return (
        <div className="btn-toolbar justify-content-between align-items-center my-2" role="toolbar"
             aria-label="Toolbar with button groups">
            <div className={"btn-toolbar mx-2"}>
                <TimeSwitch onClickNext={onClickNext} onClickPrev={onClickPrev} onClickToday={onClickToday}/>
                <TypeSwitch type={type} onChangeType={onChangeType} />
            </div>

            <HeaderTitle type={type} startTime={startTime} endTime={endTime} />

            <div>
                <div className="btn-group">
                    <label
                        className={classNames("btn btn-outline btn-active-primary btn-sm", viewType === 'resource' && 'active')}>
                        <button className="btn-check" onClick={() => onSelectViewType('resource')}/>
                        Ressourcen
                    </label>

                    <label
                        className={classNames("btn btn-outline btn-color-muted btn-active-primary btn-sm", viewType === 'orders' && 'active')}>
                        <button className="btn-check" onClick={() => onSelectViewType('orders')}/>
                        Aufträge
                    </label>
                </div>
                <Button size='small' type={"primary"} className={'ms-1 btn-success'} onClick={() => addOrder(true)}
                        icon={<AddIcon/>} label={"Auftrag"}></Button>
            </div>
        </div>
    )
}

export default Header
