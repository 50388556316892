import React from 'react'
import { Field } from 'react-final-form'

const ErrorField = ({ name }) => (
  <Field
    name={name}
    subscription={{ touched: true, error: true, submitError: true }}
    render={({ meta: { touched, error, submitError } }) =>
      touched && (error || submitError) ? <div className="form-error-message">{error || submitError}</div> : null
    }
  />
)

export default ErrorField
