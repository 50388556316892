import React from 'react'
import * as style from './index.module.scss';

const ModalHeader = ({ title, onClose }) => {

  return <div className="modal-header">
    <h3>{title}</h3>
    <div className="btn btn-sm btn-icon btn-active-color-primary">
      <i onClick={onClose} className="ki-duotone ki-cross fs-1">
        <span className="path1"></span>
        <span className="path2"></span>
      </i>
    </div>
  </div>
}

export default ModalHeader;
