import React from "react";

const ArrowsCircle = ({ className = null} ) => {
  return <i className={`ki-duotone ki-arrows-circle ${className}`}>
    <span className="path1"></span>
    <span className="path2"></span>
  </i>
}

export default ArrowsCircle;
