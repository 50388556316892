import React, { useMemo } from 'react';
import BaseTable from '../../components/Table';
import { isEmpty } from 'lodash';
import { axiosInstance, routes } from '../../utils/api_base';
import { pushToHistory } from '../../utils/base';
import { useTranslation } from 'react-i18next';

const Table = ({ tableToolbar }) => {
  const { t, i18n } = useTranslation()

  const getArticleDisposals = async (pagination, sorting, globalFilter, customFilters) => {
    const { pageIndex, pageSize } = pagination
    const sortQuery = sorting.map((attr) => {
      return attr.desc ? `${attr.id} desc` : attr.id
    })
    let params = { page: pageIndex + 1, limit: pageSize, sort: sortQuery[0] }
    if (globalFilter) {
      params.query = globalFilter
    }
    if (!isEmpty(customFilters)) {
      params = {...params, ...customFilters}
    }
    const res = await axiosInstance.get(routes.articleDisposals(), { params: params })
    pushToHistory(params)

    return(
      { rows: res.data.data, pageCount: res.data.meta.total_pages, rowCount: res.data.meta.total_count }
    )
  }

  const articleHtml = (row) => {
    return(
      <a href={row?.original?.attributes?.article_link}>
        {row?.original?.attributes?.article_name}
      </a>
    )
  }

  const orderHtml = (row) => {
    return(
      <a href={row?.original?.attributes?.order_link}>
        {row?.original?.attributes?.order_name}
      </a>
    )
  }

  const fieldsHtml = (row) => {
    return(
      row?.original?.attributes?.order_fields_names?.map((field, index) => (
        <div key={index}>{field}<br /></div>
      ))
    )
  }

  const columns = useMemo(
    () => [
      {
        id: 'article_name',
        header: t('article'),
        accessorFn: row => row?.attributes?.article_name,
        enableSorting: true,
        cell: ({ row }) => {
          return articleHtml(row)
        },
      },
      {
        id: 'order_name',
        header: t('order'),
        accessorFn: row => row?.attributes?.order_name,
        enableSorting: true,
        cell: ({ row }) => {
          return orderHtml(row)
        },
      },
      {
        id: 'order_short_date',
        accessorFn: row => row?.attributes?.order_short_date,
        header: t('date'),
        enableSorting: false,
        cell: row => row.getValue()
      },
      {
        id: 'quantity_with_unit',
        accessorFn: row => row?.attributes?.quantity_with_unit,
        header: t('quantity'),
        enableSorting: false,
        cell: row => row.getValue()
      },
      {
        id: 'order_fields_names',
        accessorFn: row => row?.attributes?.order_fields_names,
        header: t('field'),
        enableSorting: false,
        cell: ({ row }) => {
          return fieldsHtml(row)
        },
      },
    ],
    [i18n.language]
  )

  return (
    <BaseTable
      columns={columns}
      getDataFn={getArticleDisposals}
      initialSortDirection={'asc'}
      tableToolbar={tableToolbar}
    />
  )
}

export default Table;
