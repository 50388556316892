import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react'
import { useTranslation } from 'react-i18next';
import {axiosInstance, routes} from '../../utils/api_base';
import Toastr from '../../components/Toastr';
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  flexRender,
} from '@tanstack/react-table'

const RevenueTable = ({columnData, type}) => {
  const { t, i18n } = useTranslation()
  const [data, setData] = useState([])

  const getData = (path) => {
    axiosInstance.get(path)
      .then(response => {
        setData(response.data)
      })
      .catch(error => {
        Toastr({
          type: 'error',
          message: error.response?.data?.error || t('critical_error')
        })
      })
  }

  const updateRevenueAccount = (id, value) => {
    if (id) {
      axiosInstance.put(routes.revenueAccount(id), {revenue_account_no: value})
        .catch(error => {
          Toastr({
            type: 'error',
            message: error.response?.data?.error || t('critical_error')
          })
        })
    }
  }

  useEffect(()=> {
    const path = type === 'base' ? routes.revenue_statistics() : routes.company_statistics()
    getData(path)
  }, [])

  const dynamicColumns = () => {
    return columnData.map((cData) => {
      return(
        {
          id: cData.id,
          accessorFn: row => row[cData.id] === null ? 'N/A' : row[cData.id],
          header: cData.name,
        }
      )
    })
  }

  const columns = useMemo(
    () => [
      {
        id: 'name',
        accessorFn: row => row?.name,
        header: t('shared.name'),
        cell: row => row.getValue(),
      },
      ...dynamicColumns()
    ], [columnData, i18n.language]
  )

  const useSkipper = () => {
    const shouldSkipRef = useRef(true)
    const shouldSkip = shouldSkipRef.current

    const skip = useCallback(() => {
      shouldSkipRef.current = false
    }, [])

    React.useEffect(() => {
      shouldSkipRef.current = true
    })

    return [shouldSkip, skip]
  }

  const defaultColumn = {
    cell: ({ getValue, row: { index }, column: { id }, table }) => {
      const initialValue = getValue()['number'] === null ? '' : getValue()['number']
      if (id === 'name') {
        return initialValue
      }
      // We need to keep and update the state of the cell normally
      const [value, setValue] = React.useState(initialValue)

      // When the input is blurred, we'll call our table meta's updateData function
      const onBlur = () => {
        table.options.meta?.updateData(index, id, value, getValue()['id'])
      }

      // If the initialValue is changed external, sync it up with our state
      React.useEffect(() => {
        setValue(initialValue)
      }, [initialValue])

      return (
        <input
          value={value}
          onChange={e => setValue(e.target.value)}
          onBlur={onBlur}
        />
      )
    },
  }

  const [autoResetPageIndex, skipAutoResetPageIndex] = useSkipper()

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    defaultColumn,
    autoResetPageIndex,
    meta: {
      updateData: (rowIndex, columnId, value, revenueAccountId) => {
        updateRevenueAccount(revenueAccountId, value)
        skipAutoResetPageIndex()
        setData(old =>
          old.map((row, index) => {
            if (index === rowIndex) {
              return {
                ...old[rowIndex], [columnId]: value,
              }
            }
            return row
          })
        )
      },
    },
  })

  return(
    <div className="card-body pt-0">
      <div id="kt_ecommerce_sales_table_wrapper" className="dt-container dt-bootstrap5 dt-empty-footer">
        <div className="row mt-2 justify-content-md-center">
          <div className="col-12">
            <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable">
              <thead>
              {table.getHeaderGroups().map(headerGroup => (
                <tr className="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0" key={headerGroup.id}>
                  {headerGroup.headers.map(header => (
                    <th key={header.id} colSpan={header.colSpan}>
                      {
                        !header.isPlaceholder &&
                          (<div
                            className={header.column.getCanSort() ? 'cursor-pointer select-none' : ''}
                            onClick={header.column.getToggleSortingHandler()}
                          >
                            {flexRender(header.column.columnDef.header, header.getContext())}
                            {header.column.getIsSorted() === 'asc' && <i className="ki-duotone ki-up" />}
                            {header.column.getIsSorted() === 'desc' && <i className="ki-duotone ki-down" />}
                          </div>)
                      }
                    </th>
                  ))}
                </tr>
              ))}
              </thead>
              <tbody className="fw-semibold text-gray-600">
              {table.getRowModel().rows.map(row => (
                <tr key={row.id}>
                  {row.getVisibleCells().map(cell => (
                    <td key={cell.id} style={{ width: cell.column.getSize() }}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  ))}
                </tr>
              ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RevenueTable
