import React from 'react'
import Dropdown from 'react-bootstrap/Dropdown'
import {FilterIcon} from '../Icons';
import { useTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form'
import classNames from 'classnames'
import Select from 'react-select'
import TableButton from './TableButton';
import { mapValues, find, cloneDeep } from "lodash"

const Filters = ({filters, initialValues, setFilters}) => {
  const { t } = useTranslation()

  const mapOptions = (options, isAllIncluded) => {
    let selectOptions = options.map(array => {
      return { label: array[1], value: array[0] }
    })
    if(isAllIncluded) {
      selectOptions.unshift({ label: t('all'), value: "All" })
    }

    return selectOptions
  }

  const preparedFilters = React.useMemo(() => {
    let clonedFilters = cloneDeep(filters)
    return clonedFilters.map((filter) => {
      filter.options = mapOptions(filter.options, filter.include_all)
      return filter
    })
  }, filters)

  const preparedInitialValues = React.useMemo(() => {
    return mapValues(initialValues,(value, key) =>
      {
        let filter = find(preparedFilters, { attribute: key })

        if (filter.select && filter.type !== 'number') {
          if (Array.isArray(value)) {
            return value.map(val => find(filter.options, { value: val }) ||
                                    find(filter.options, { value: Number(val) }) ||
                                    { label: t('all'), value: 'All' });
          } else {
            return find(filter.options, { value: value }) ||
                   find(filter.options, { value: Number(value) }) ||
                  { label: t('all'), value: 'All' };
          }
        }

        return value
      }
    )
}, [initialValues])

  const onSubmit = async values => {
    const filterValues = mapValues(values, (value, key)=> {
      return Array.isArray(value)
        ? value.map(item => item.value)
        : (value.value ?? value);
    })
    setFilters(filterValues)
  }

  const ReactSelectAdapter = ({ input, ...rest }) => {
    return <Select
      placeholder={t("filter.please_select")}
      {...input}
      {...rest}
      searchable/>
  }

  const filterHtml = (filter, index) => {
    if (filter.type === 'number') {
      return (
        <div key={index} className="mb-10">
          <label className="form-label fs-6 fw-semibold">{filter.label}</label>
          <Field
            name={filter.attribute}
            component="input"
            type={filter.type}
            className="form-control form-control-sm"
          />
        </div>
      );
    }

    return (
      <div key={index} className="mb-10">
        <label className="form-label fs-6 fw-semibold">{filter.label}</label>
        <Field
          name={filter.attribute}
          component={ReactSelectAdapter}
          options={filter.options}
          isMulti={filter.isMulti}
        />
      </div>
    );
  };

  const popperConfig = {
    modifiers: [
      {
        name: "preventOverflow",
        options: {
          boundary: "viewport"
        }
      }
    ]
  };

  const customMenu = React.forwardRef(({ style, className }, ref) => {
      return (
        <div
          ref={ref}
          style={style}
          className={classNames(className, "menu menu-sub menu-sub-dropdown w-300px w-md-325px")}
        >
          <div className="px-7 py-5">
            <div className="fs-5 text-gray-900 fw-bold">{t("filter.filter_options")}</div>
          </div>
          <div className="separator border-gray-200" />
          <div className="px-7 py-5">
              <Form
                onSubmit={onSubmit}
                initialValues={preparedInitialValues}
                render={({ handleSubmit, form, submitting, pristine, values }) => (
                  <form onSubmit={handleSubmit}>
                    {preparedFilters.map((filter, index) => {
                      return filterHtml(filter, index)
                    })}
                    <div className="d-flex justify-content-end">
                      <TableButton
                        className="btn btn-light btn-active-light-primary fw-semibold me-2 px-6"
                        type="button"
                        onClick={form.reset}
                        title={t("filter.reset")}
                        disabled={submitting || pristine}
                      />
                      <TableButton
                        className="btn btn-primary fw-semibold px-6"
                        type="submit"
                        title={t("filter.apply")}
                        disabled={submitting || pristine}
                      />
                    </div>
                    {/*<pre>{JSON.stringify(values, 0, 2)}</pre>*/}
                  </form>
                )}
              />
            </div>
        </div>
      )
  })

  return(
    <Dropdown align={"end"} className="d-inline mx-2" autoClose="outside" id="main-dropdown">
      <Dropdown.Toggle className="btn-light-primary me-3" id="custom-dropdown-autoclose-outside">
        <>
          <FilterIcon />
          {t('shared.filter')}
        </>
      </Dropdown.Toggle>
      <Dropdown.Menu as={customMenu} popperConfig={popperConfig} id="main-dropdown-menu"/>
    </Dropdown>
  )
}

export default Filters
