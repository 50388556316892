import React, { useMemo } from 'react';
import BaseTable from '../Table';
import { isEmpty } from 'lodash';
import { axiosInstance, routes } from '../../utils/api_base';
import { pushToHistory } from '../../utils/base';
import { useTranslation } from 'react-i18next';

const Table = ({ tableToolbar }) => {
  const { t, i18n } = useTranslation()

  const getServiceFeatures = async (pagination, sorting, globalFilter, customFilters) => {
    const { pageIndex, pageSize } = pagination
    const sortQuery = sorting.map((attr) => {
      return attr.desc ? `${attr.id} desc` : attr.id
    })
    let params = { page: pageIndex + 1, limit: pageSize, sort: sortQuery[0] }
    if (globalFilter) {
      params.query = globalFilter
    }
    if (!isEmpty(customFilters)) {
      params = { ...params, ...customFilters }
    }
    const res = await axiosInstance.get(routes.serviceFeatures(), { params: params })
    pushToHistory(params)

    return (
      { rows: res.data.data, pageCount: res.data.meta.total_pages, rowCount: res.data.meta.total_count }
    )
  }

  const columns = useMemo(
    () => [
      {
        id: 'name',
        header: t('shared.surcharge'),
        accessorFn: row => row?.attributes?.name,
        size: 100,
        minSize: 100,
        enableSorting: true,
        cell: row => row.getValue(),
      },
      {
        id: 'service_long_name',
        header: t('shared.service'),
        enableSorting: true,
        cell: ({ row }) => {
          const attributes = row?.original?.attributes
          return <><a key={attributes.id} href={attributes.service_link}>{attributes.service_long_name}</a><br /></>
        },
      },
      {
        id: 'business_partner_long_name',
        accessorFn: row => row?.attributes?.business_partner_long_name,
        header: t('service_features.customized'),
        enableSorting: true,
        cell: row => row.getValue(),
      },
      {
        id: 'offer_name',
        header: t('shared.offer'),
        enableSorting: true,
        cell: ({ row }) => {
          const attributes = row?.original?.attributes
          return <><a key={attributes.id} href={attributes.offer_link}>{attributes.offer_name}</a><br /></>
        },
      },
    ],
    [i18n.language]
  )

  return (
    <BaseTable
      getDataFn={getServiceFeatures}
      columns={columns}
      tableToolbar={tableToolbar}
    />
  )
}

export default Table;
