import React, { useState } from 'react';
import { usePopper } from 'react-popper';
import PropTypes from 'prop-types';

const Popover = ({text, body, placement, header=null}) => {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [label, setLabel] = useState(text);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: placement,
    modifiers: [
      { name: 'arrow', options: { element: arrowElement, padding: 10 } },
      { name: 'offset', options: { offset: [0, 8] } }
    ]
  });

  const toggleTooltip = () => {
    setIsVisible(!isVisible);
  };

  return (
    <>
      <button className="btn btn-secondary my-3 me-5"
        type="button"
        ref={setReferenceElement}
        onClick={toggleTooltip}>
        {label}
      </button>

      {isVisible && (
        <div className="popover bs-popover-auto" ref={setPopperElement} style={styles.popper} {...attributes.popper}>
          {header && <h3 className="popover-header">{header}</h3>}
          <div className="popover-body">
            {body}
          </div>
          <div className="popover-arrow" ref={setArrowElement} style={styles.arrow} />
        </div>
      )}
    </>
  );
};

Popover.defaultProps = {
  placement: 'bottom'
}

Popover.propTypes = {
  text: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  placement: PropTypes.oneOf(['bottom', 'top', 'left', 'right']),
  header: PropTypes.string
};

export default Popover
