import PropTypes from 'prop-types';

import * as toastr from '../../sources/js/components/toastr.min'

const Toastr = ({type = 'success', title = '', message, options = {}}) => {
  toastr.options = options

  switch (type) {
    case 'success':
      toastr.success(message, title)
      break;
    case 'info':
      toastr.info(message, title)
      break;
    case 'warning':
      toastr.warning(message, title)
      break;
    case 'error':
      toastr.error(message, title)
      break;
    default:
      console.log('ERROR: Toastr type is not supported')
  }
}

Toastr.defaultProps = {
  type: 'success',
  title: '',
  options: {}
}

Toastr.propTypes = {
  type: PropTypes.oneOf(['success', 'info', 'warning', 'error']),
  title: PropTypes.string,
  message: PropTypes.string.isRequired,
  options:{
    closeButton: PropTypes.bool,
    debug: PropTypes.bool,
    newestOnTop: PropTypes.bool,
    progressBar: PropTypes.bool,
    positionClass: PropTypes.string,
    preventDuplicates: PropTypes.bool,
    onclick: PropTypes.func,
    showDuration: PropTypes.number,
    hideDuration: PropTypes.number,
    timeOut: PropTypes.number,
    extendedTimeOut: PropTypes.number,
    showEasing: PropTypes.string,
    hideEasing: PropTypes.string,
    showMethod: PropTypes.string,
    hideMethod: PropTypes.string
  }
};

export default Toastr
