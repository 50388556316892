import React, { useMemo } from 'react';
import BaseTable from '../Table';

const Table = ({ tableToolbar }) => {
  // TODO: Rewrite with Articles Table
  const columns = useMemo(() => [{ id: 'id' }])

  return (
    <BaseTable
      columns={columns}
      columnVisibility={{}}
      tableToolbar={tableToolbar}
      initialSortDirection={'asc'}
    />
  )
}

export default Table;
