import React from 'react'

const Input = ({ value = '', onChange = () => null, placeholder = '', required = false }) => {

    return <>
        <label className={ `${required ? 'required' : ''} fs-5 fw-semibold mb-2` }>{ placeholder }</label>
        <input type="text" className="form-control" placeholder="" defaultValue={value} onChange={(event) =>onChange(event.target.value)}/>
    </>
}

export default Input
