import React from "react";

const CloudDownload = ({ className = null} ) => {
  return <i className={`ki-duotone ki-cloud-download ${className}`}>
    <span className="path1"></span>
    <span className="path2"></span>
  </i>
}

export default CloudDownload;
