import React, {useState, useEffect} from "react";
import Select from "../../../components/Select";
import Button from "../../../components/Button";
import DateRangePicker from "../../../components/DateRangePicker";
import {useTranslation} from 'react-i18next';
import moment from 'moment'
import {axiosInstance, routes} from "../../../utils/api_base";

const StaffOrderForm = ({staffOrder, staffs, staffId, services, time}) => {
    const [machines, setMachines] = useState([])
    const [state, setState] = useState({
        ...staffOrder,
        staffId,
        machineId: 0,
        startTime: moment(time),
        endTime: moment(time).add(5, "hours")
    })

    const getMachines = async (pagination) => {
        const {pageIndex, pageSize} = pagination
        const params = {page: pageIndex + 1, limit: pageSize, serialize: 'short', active: true}
        const res = await axiosInstance.get(
            routes.machines(),
            {
                params: params
            }
        )

        return (
            {rows: res.data.data, pageCount: res.data.meta.total_pages, rowCount: res.data.meta.total_count}
        )
    }


    useEffect(() => {
        getMachines({ pageIndex: 0, pageSize: 10 }).then((response) => setMachines(response.rows))
    }, []);

    const {t, i18n} = useTranslation()

    return <div className={'container'}>
        <div className={"row gx-2"}>
            <div className={'col-6'}>
                <label className="required fs-5 fw-semibold mb-2">Mitarbeiter</label>
                <Select options={staffs.map((entity) => ({
                    id: entity.id,
                    text: entity.attributes.name
                }))}
                        value={staffId}
                        onChange={(value) => setState((state) => ({...state, staffId: value}))}
                        multiple={false} placeholder={'Mitarbeiter'}/>
            </div>
            <div className={'col-6'}>
                <label className="required fs-5 fw-semibold mb-2">Gespann</label>
                <Select placeholder={'Gespann'}></Select>
            </div>
        </div>

        <div className={"row gx-2 mt-2"}>
        <div className={'col-4'}>
                <label className="required fs-5 fw-semibold mb-2">Fahrzeug</label>
                <Select options={machines.map((entity) => ({
                    id: entity.id,
                    text: entity.attributes.long_name
                }))}
                        url={routes.machines()}
                        params={{
                            active: true,
                            serialize: 'short'
                        }}
                        formatData={(data) => data.map(item => ({ id: item.id, text: item.attributes.long_name }))}
                        value={state.machineId}
                        onChange={(value) => setState((state) => ({...state, machineId: value}))}
                        multiple={false} placeholder={'Fahrzeug'}/>
            </div>
            <div className={'col-4'}>
                <label className="required fs-5 fw-semibold mb-2">Gerät 1</label>
                <Select placeholder={'Gerät 1'}></Select></div>
            <div className={'col-4'}>
                <label className="required fs-5 fw-semibold mb-2">Gerät 2</label>
                <Select placeholder={'Gerät 2'}></Select></div>
        </div>

        <div className={"row gx-2 mt-2"}>
        <div className={"col-6"}>
                <label className="required fs-5 fw-semibold mb-2">Dienstleistung</label>
                <Select options={services.map((entity) => ({
                    id: entity.id,
                    text: entity.attributes.long_name
                }))}
                        onChange={(value) => setState((state) => ({...state, serviceId: value}))}
                        multiple={false} placeholder={'Dienstleistung'}/>
            </div>
            <div className={"col-6"}>
                <label className="required fs-5 fw-semibold mb-2">Zeitraum</label>
                <DateRangePicker
                    placeholder=''
                    language={i18n.language}
                    classList="cursor-pointer form-control text-left"
                    options={{
                        timePicker: true,
                        showDropdowns: true,
                        startDate: state.startTime,
                        endDate: state.endTime,
                        onChange: (startTime, endTime) => setState((state) => ({...state, startTime, endTime})),
                        autoApply: false
                    }}
                />
            </div>
        </div>


        <Button variant={'primary'}>Speichern</Button>
    </div>
}

export default StaffOrderForm
