import React from 'react';
import Table from './Table';

const ServiceGroupStatistics = ({ filters }) => {
  const tableToolbar = () => {
    return (<></>)
  }

  return (
    <Table filters={filters} tableToolbar={tableToolbar} />
  )
}

export default ServiceGroupStatistics;
