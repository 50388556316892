import React from 'react';
import Table from './Table';
import { useTranslation } from 'react-i18next';
import TableButton from '../Table/TableButton';
import { PlusIcon } from '../Icons';

const PurchaseBills = ({ filters }) => {
  const { t, i18n } = useTranslation();

  const tableToolbar = () => {
    return (
      <>
        <TableButton href={'/purchase_bills/priceless'} type="button" className="btn-light-primary me-3" title={t('entrances_without_price')} />
        <TableButton className="btn-primary me-3" icon={<PlusIcon />} title={t('new_goods_receipt')} />
      </>
    )
  }

  return (
    <Table
      tableToolbar={tableToolbar}
      filters={filters}
    />
  )
}

export default PurchaseBills;
