import React, { useMemo, Fragment, useState, useEffect } from 'react';
import { axiosInstance, routes } from '../../utils/api_base';
import { useTranslation } from 'react-i18next';
import { pushToHistory } from '../../utils/base';
import BaseTable from '../../components/Table';
import JobProgressBar from '../../components/JobProgressBar';
import TableButton from '../../components/Table/TableButton';
import { isEmpty } from 'lodash';
import pluralize from 'pluralize';
import classNames from 'classnames'

const Table = ({tableToolbar, businessPartnerId, prevJobId}) => {
  const { t, i18n } = useTranslation()
  const [selectedRows, setSelectedRows] = useState({});
  const [buttonTitle, setButtonTitle] = useState('');
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [showSubmitButton, setShowSubmitButton] = useState(true);
  const [jobId, setJobId] = useState();
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [disableCheckBoxes, setDisableCheckBoxes] = useState(false);
  const [dataRows, setDataRows] = useState([]);

  useEffect(() => {
    if (prevJobId) {
      setShowProgressBar(true)
      setShowSubmitButton(false)
      setJobId(prevJobId)
      setDisableCheckBoxes(true)
    }
  }, []);

  useEffect(() => {
    const allChecked = dataRows.reduce((acc, row) => {
      acc[row.id] = row.id;
      return acc;
    }, {});
    setSelectedRows(allChecked);
  }, [dataRows]);

  useEffect(() => {
    correctTextForButton()
  }, [selectedRows]);

  const getDunnings = async (pagination, sorting, globalFilter, customFilters) => {
    let params = {business_partner_id: businessPartnerId}

    if (globalFilter) {
      params.query = globalFilter
    }
    if (!isEmpty(customFilters)) {
      params = { ...params, ...customFilters }
    }
    const res = await axiosInstance.get(routes.businessPartnerDunningBills(), { params: { ...params } })
    pushToHistory(params)
    setDataRows(res.data.data);

    return (
      { rows: res.data.data }
    )
  }

  const handleCheckboxChange = (id) => {
    setSelectedRows(prev => {
      const newSelectedRows = { ...prev };
      if (newSelectedRows[id]) {
        delete newSelectedRows[id];
      } else {
        newSelectedRows[id] = id;
      }
      return newSelectedRows;
    });
  };

  const correctTextForButton = () => {
    const selectedCount = Object.keys(selectedRows).length;

    if (selectedCount >= 1 ) {
      const dunningText = t('dunning.ueber_dunnings.dunning_for_bills_size', { bills_size: selectedCount });
      const invoiceText = pluralize(t('bills.invoice'), selectedCount);
      setButtonTitle(`${dunningText} ${invoiceText}`)
    } else {
      setButtonTitle(t('shared.no_bills'))
    }
  }

  const handleSubmit = async () => {
    setDisableSubmit(true)
    setShowSubmitButton(false)
    const selectedIds = Object.keys(selectedRows);
    
    try {
      const resp = await axiosInstance.post(routes.ueberDunnings(), { business_partner_id: businessPartnerId, bills: selectedIds });
      setJobId(resp.data.job_id)
      setShowProgressBar(true)
    } catch (error) {
      setShowProgressBar(false)
      console.error('Error submitting selected bills:', error);
    }
  };

  const invoiceLink = (row) => {
    return (
      <TableButton
        type="button"
        target="_blank"
        href={row?.original?.attributes?.link}
        title={row?.original?.attributes?.bill_number}
        className="btn-light-primary"
      />
    )
  }

  const dunningsHtml = (row) => {
    const dunnings = row?.original?.attributes?.dunnings || []
    return (
      <>
        {dunnings.length > 0 ? (
          dunnings.map((dunning, index) => (
            dunningHtml(dunning, index)
          ))
        ) : (
          'N/A'
        )}
      </>
    )
  }

  const invoiceHtml = (row) => {
    const amount = row?.original?.attributes?.invoice_amount
    const paid_sum = row?.original?.attributes?.paid_sum
    
    return (
      <>
        {amount}
        {paid_sum && <p className="small text-success text-capitalize">{paid_sum}</p>}
      </>
    )
  }

  const dunningHtml = (dunning, index) => {
    return (
      <Fragment key={index}>
        <a target="_blank" href={dunning.attributes.link}>
          {dunningName(dunning.attributes.level)}
        </a>
        <br/>
      </Fragment>
    )
  }

  const dunningName = (level) => {
    switch (level) {
      case 0:
        return t('dunning.payment_reminder')
      case 1:
        return t('dunning.first_reminder')
      case 2:
        return t('dunning.second_reminder')
      case 3:
        return t('dunning.third_reminder')
    }
  }

  const checkBoxRow = (row) => {
    return (
      <div className="form-check form-check-solid me-10">
        <input
          className="form-check-input"
          type="checkbox"
          disabled={disableCheckBoxes}
          id={row.original.id}
          name="bills[]"
          checked={!!selectedRows[row.original.id]}
          onChange={() => handleCheckboxChange(row.original.id)}
        />
        <label className="form-check-label"></label>
      </div>
    )
  }

  const columns = useMemo(
    () => [
      {
        id: 'code',
        header: t('dunning.ueber_dunnings.invoice'),
        accessorFn: row => row?.attributes?.id,
        size: 70,
        minSize: 70,
        enableSorting: false,
        cell: ({ row }) => invoiceLink(row)
      },
      {
        id: 'date_of_invoice',
        minSize: 70,
        size: 70,
        enableSorting: false,
        accessorFn: row => row?.attributes?.date_of_invoice,
        header: t('dunning.ueber_dunnings.date_of_invoice'),
        cell: row => row.getValue(),
      },
      {
        id: 'deadline',
        size: 70,
        minSize: 70,
        enableSorting: false,
        accessorFn: row => row?.attributes?.deadline,
        header: t('dunning.ueber_dunnings.deadline'),
        cell: row => row.getValue(),
      },
      {
        id: 'invoice_amount',
        enableSorting: false,
        accessorFn: row => row?.attributes?.invoice_amount,
        header: t('dunning.ueber_dunnings.invoice_amount'),
        cell: ({ row }) => invoiceHtml(row)
      },
      {
        id: 'reminders',
        size: 70,
        minSize: 70,
        enableSorting: false,
        accessorFn: row => row?.attributes?.id,
        header: t('dunning.ueber_dunnings.reminders'),
        cell: ({ row }) => dunningsHtml(row)
      },
      {
        id: 'actions',
        size: 70,
        minSize: 70,
        enableSorting: false,
        accessorFn: row => row?.attributes?.id,
        header: '',
        cell: ({ row }) => checkBoxRow(row),
      },
    ],
    [i18n.language, selectedRows]
  )

  return (
    <div>
      <BaseTable
        columns={columns}
        columnVisibility={{}}
        getDataFn={getDunnings}
        showSearchInput={false}
        enablePagination={false}
        initialSortDirection={'asc'}
        enableSorting={false}
        tableToolbar={tableToolbar}
      />
      {showSubmitButton && 
        <TableButton
          type="button"
          onClick={handleSubmit}
          className={classNames("btn btn-primary mt-3", (isEmpty(selectedRows) || disableSubmit) ? 'disabled' : '')}
          title={buttonTitle}
        />
      }
      {showProgressBar && <JobProgressBar jobId={jobId}/>}
    </div>
  );
}

export default Table;
