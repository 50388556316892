import React from "react";

const ChartPie = ({ className }) => {
  return (
    <i className={`ki-duotone ki-chart-pie-3 fs-2 ${className}`}>
      <span className="path1"></span>
      <span className="path2"></span>
      <span className="path3"></span>
    </i>
  )
}
export default ChartPie;
