import React from 'react';

const Navigation = ({ items }) => {
  return (
    <div
      id="kt_nav"
      className="rounded bg-gray-200 d-flex flex-stack flex-wrap mb-6 p-2"
      data-kt-sticky-left="auto"
      data-kt-sticky-top="70px"
      data-kt-sticky-animation="false"
      data-kt-sticky-zindex="95"
    >
      <ul className="nav w-100 flex-wrap border-transparent">
        {items.map((item, index) => (
          <li key={index} className="flex-grow-1 nav-item my-1">
            <a
              className={`btn btn-sm btn-color-gray-600 bg-state-body btn-active-color-gray-800 fw-bolder fw-bold fs-6 fs-lg-base nav-link px-3 px-lg-4 mx-1 ${item.active ? 'active' : ''}`}
              href={item.href}
            >
              {item.label}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Navigation;