import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TableButton from '../Table/TableButton';
import { PlusIcon } from '../Icons';
import Table from './Table';
import Create from './Create';
import Modal from '../Modal';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import queryString from 'query-string';

const Units = ({ is_admin }) => {
  const { t } = useTranslation()
  const [modalOpen, setModalOpen] = useState(false)
  const columnVisibility = { key: is_admin, code: is_admin }
  const parsedQuery = queryString.parse(location.search)
  const [activeTab, setActiveTab] = useState(parsedQuery.unit_type);

  const tableToolbar = () => {
    return (
      <>
        <TableButton
          className="btn-primary"
          onClick={() => setModalOpen(true)}
          icon={<PlusIcon />}
          title={t('shared.add_to')}
        />
      </>
    )
  }

  return (
    <>
      <Modal className="modal fade show" open={modalOpen} onClose={() => setModalOpen(false)} title={t('units.create_new_unit')}>
        <Create onClose={() => setModalOpen(false)} />
      </Modal>
      <Tabs
        onSelect={(k) => setActiveTab(k)}
        defaultActiveKey={activeTab}
        id="unit-tabs"
        className="mb-3"
      >
        <Tab eventKey="base" title={t('basic_units')} mountOnEnter={true} unmountOnExit={true}>
          <Table tableToolbar={tableToolbar} columnVisibility={columnVisibility} unit_type="base" />
        </Tab>
        <Tab eventKey="non_basic" title={t('non_basic_units')} mountOnEnter={true} unmountOnExit={true}>
          <Table tableToolbar={tableToolbar} columnVisibility={columnVisibility} unit_type="non_basic" />
        </Tab>
        <Tab eventKey="client" title={t('customized_units')} mountOnEnter={true} unmountOnExit={true}>
          <Table tableToolbar={tableToolbar} columnVisibility={columnVisibility} unit_type="client" />
        </Tab>
      </Tabs>
    </>
  )
}

export default Units;
