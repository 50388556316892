import React from "react";
import { useDrag } from 'react-dnd';
import * as style from './index.module.scss'

import classNames from 'classnames'

const OrderItem = ({order, onClick}) => {
    const [{ isDragging }, drag] = useDrag(() => ({
        type: 'BOX',
        item: order,
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
    }));

    return <button ref={drag} className={classNames(style['root'], 'card', 'mb-2', 'p-2')} onClick={onClick}>
        { order.id }
    </button>
}

export default OrderItem
