import './i18n'
import ReactOnRails from 'react-on-rails';

import '../sources/js/main'
import "../css/application.scss"
import "../css/global.scss"
import "../css/variables.scss"

import ArticleBills from '../pages/ArticleBills';
import ArticleConsumptions from '../pages/ArticleConsumptions';
import ArticleDisposals from '../pages/ArticleDisposals';
import ArticleGroups from '../pages/ArticleGroups';
import ArticlePurchases from '../pages/ArticlePurchases';
import Articles from '../components/Articles';
import Bills from '../components/Bills';
import BusinessPartners from '../components/BusinessPartners';
import Combinations from '../pages/Combinations';
import DeliverySlips from '../components/DeliverySlips';
import Disposition from '../pages/Disposition'
import DocumentOfBookingReports from '../pages/DocumentOfBookingReports';
import Dunnings from '../components/Dunnings';
import Footer from '../components/layouts/Footer';
import JobProgressBar from '../components/JobProgressBar';
import Languages from '../components/layouts/Header/Languages';
import MachineGroupForServiceFeatures from '../pages/MachineGroupForServiceFeatures';
import MachineGroupStatistics from '../pages/MachineGroupStatistics';
import MachineRepairs from '../pages/MachineRepairs';
import Machines from '../components/Machines';
import MakeMeHappy from '../components/MakeMeHappy';
import Offers from '../components/Offers';
import Orders from '../components/Orders';
import Popover from '../components/Popover';
import PurchaseBills from '../components/PurchaseBills';
import RemunerationPayrolls from '../pages/RemunerationPayrolls';
import RentalMachines from '../pages/RentalMachines';
import RevenueAccounts from '../pages/RevenueAccounts';
import SelectPeriod from '../components/SelectPeriod';
import ServiceBusinessPartnerStatistics from '../components/ServiceBusinessPartnerStatistics';
import ServiceFeatureChronicles from '../components/ServiceFeatureChronicles';
import ServiceFeatures from '../components/ServiceFeatures';
import ServiceGroupStatistics from '../pages/ServiceGroupStatistics';
import Services from '../components/Services';
import Sites from '../components/Sites';
import StaffOvertime from '../pages/StaffOvertime';
import Staffs from '../components/Staffs';
import Toastr from '../components/Toastr';
import UeberDunningBusinessPartner from '../pages/UeberDunningBusinessPartner';
import UeberDunnings from '../pages/UeberDunnings';
import Units from '../components/Units';
import Vendors from '../pages/Vendors';

// This is how react_on_rails can see the HelloWorld in the browser.
ReactOnRails.register({
  ArticleBills,
  ArticleConsumptions,
  ArticleDisposals,
  ArticleGroups,
  ArticlePurchases,
  Articles,
  Bills,
  BusinessPartners,
  Combinations,
  DeliverySlips,
  Disposition,
  DocumentOfBookingReports,
  Dunnings,
  Footer,
  JobProgressBar,
  Languages,
  MachineGroupForServiceFeatures,
  MachineGroupStatistics,
  MachineRepairs,
  Machines,
  MakeMeHappy,
  Offers,
  Orders,
  Popover,
  PurchaseBills,
  RemunerationPayrolls,
  RentalMachines,
  RevenueAccounts,
  SelectPeriod,
  ServiceBusinessPartnerStatistics,
  ServiceFeatureChronicles,
  ServiceFeatures,
  ServiceGroupStatistics,
  Services,
  Sites,
  StaffOvertime,
  Staffs,
  Toastr,
  UeberDunningBusinessPartner,
  UeberDunnings,
  Units,
  Vendors,
});
