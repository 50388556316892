import axios from 'axios'
import ReactOnRails from 'react-on-rails';

const defaultOptions = {
  headers: ReactOnRails.authenticityHeaders()
}

axios.defaults.withCredentials = true
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'
axios.defaults.headers.common['Accept'] = 'application/json'

export const axiosInstance = axios.create(defaultOptions)

export const routes = {
  articleBillFeatures: () => '/api/frontend/article_bill_features',
  articleConsumptions: () => '/api/frontend/article_consumptions',
  articleDisposals: () => '/api/frontend/article_disposals',
  articleGroups: () => '/api/frontend/article_groups',
  articlePurchases: () => '/api/frontend/article_purchases',
  articles: () => '/api/frontend/articles',
  articlesLoadPlantProtectionProducts: () => '/articles/load_plant_protection_products',
  bills: () => '/api/frontend/bills',
  businessPartnerDunningBills: () => '/api/frontend/ueber_dunnings/bills',
  businessPartners: () => '/api/frontend/business_partners',
  combination: (id) => `/api/frontend/combinations/${id}`,
  combinations: () => '/api/frontend/combinations',
  companies: () => '/api/frontend/companies',
  company_statistics: () => '/api/frontend/revenue_accounts/company_statistics',
  currentCustomizing: () => '/api/frontend/customizings',
  customizing: (id) => `/api/frontend/customizings/${id}`,
  customizings: () => '/api/frontend/business_partners/customizings',
  dataServiceForm: () => '/api/frontend/services/data_for_service_form',
  deliverySlips: () => '/api/frontend/delivery_slips',
  documentOfBookingReport: (id) => `/api/frontend/document_of_booking_reports/${id}`,
  documentOfBookingReports: () => '/api/frontend/document_of_booking_reports',
  downloadDocumentOfBookingReports: (id) => `/api/frontend/document_of_booking_reports/${id}/download`,
  dunnings: () => '/api/frontend/dunnings',
  exports: () => '/api/frontend/exports',
  fields: () => 'api/frontend/fields',
  jobProgress: (id) => `jobs/${id}`,
  machineGroupForServiceFeatures: () => '/api/frontend/machine_group_for_service_features',
  machineGroups: () => '/api/frontend/machine_groups',
  machineGroupStatistics: () => '/api/frontend/statistics/machine_groups',
  machineRepairs: () => '/api/frontend/machine_repairs',
  machines: () => '/api/frontend/machines',
  newServices: () => '/api/frontend/services',
  offers: () => '/api/frontend/offers',
  order: (id) => `api/frontend/orders/${id}`,
  orders: () => '/api/frontend/orders',
  purchaseBills: () => '/api/frontend/purchase_bills',
  refreshDocumentOfBookingReports: (id) => `/api/frontend/document_of_booking_reports/${id}/refresh`,
  remunerationPayrolls: () => '/api/frontend/remuneration/payrolls',
  revenue_statistics: () => '/api/frontend/revenue_accounts/revenue_statistics',
  revenueAccount: (id) => `/api/frontend/revenue_accounts/${id}`,
  revenueCodes: () => '/api/frontend/revenue_codes',
  serviceBusinessPartnerStatistics: () => '/api/frontend/statistics/service_business_partners',
  serviceFeatureChronicles: () => '/api/frontend/service_feature_chronicles',
  serviceFeatures: () => '/api/frontend/service_features',
  serviceGroupStatistics: () => '/api/frontend/statistics/service_groups',
  services: () => '/api/frontend/services',
  sites: () => '/api/frontend/sites',
  staff: (id) => `api/V1/staffs/${id}`,
  staffOvertime: () => '/api/frontend/staff_overtimes',
  staffs: () => 'api/frontend/staffs',
  ueberDunnings: () => '/api/frontend/ueber_dunnings',
  units: () => '/api/frontend/units',
  updateDateRange: (id) => `/staff_customizings/${id}/update_current_start_and_end_date`,
  updateLocale: () => '/api/frontend/staff_customizings/update_locale',
  vendors: () => '/api/frontend/vendors',
}
