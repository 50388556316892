import React from 'react';
import Table from './Table';

const ArticleBills = ({ articleId }) => {
  const tableToolbar = () => { return (<></>) }
  return (
    <Table tableToolbar={tableToolbar} articleId={articleId} />
  )
}

export default ArticleBills;
