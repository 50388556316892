import React from 'react';
import Table from './Table';

const ServiceBusinessPartnerStatistics = ({ service_id, company_id, filters }) => {

  const tableToolbar = () => {
    return (
      <>
      </>
    )
  }

  return (
    <>
      <Table service_id={service_id} company_id={company_id} tableToolbar={tableToolbar} filters={filters} />
    </>
  )
}

export default ServiceBusinessPartnerStatistics;
