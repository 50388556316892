import React from "react";
import moment from 'moment'
const HeaderTitle = ({ type, startTime, endTime }) => {

    const buildTitle = () => {
        switch (type) {
            case 'day':
                return `${moment(startTime).format("LL")} - ${moment(endTime).format("LL")}`
            case 'week':
                return moment(startTime).format("[KW]WW YYYY")
            case 'month':
                return moment(startTime).format("MMMM YYYY")
        }
    }

    return <h2 className="text-center mt-3">{ buildTitle() }</h2>
}

export default HeaderTitle
