import React, { useMemo } from 'react';
import BaseTable from '../../components/Table';
import { isEmpty } from 'lodash';
import { axiosInstance, routes } from '../../utils/api_base';
import { pushToHistory } from '../../utils/base';
import { useTranslation } from 'react-i18next';
import TableButton from '../../components/Table/TableButton';
import ExpandableList from '../../components/ExpandableList';

const Table = ({ tableToolbar }) => {
  const { t, i18n } = useTranslation()

  const getMachineGroupForServiceFeatures = async (pagination, sorting, globalFilter, customFilters) => {
    const { pageIndex, pageSize } = pagination
    const sortQuery = sorting.map((attr) => {
      return attr.desc ? `${attr.id} desc` : attr.id
    })
    let params = { page: pageIndex + 1, limit: pageSize, sort: sortQuery[0] }
    if (globalFilter) {
      params.query = globalFilter
    }
    if (!isEmpty(customFilters)) {
      params = {...params, ...customFilters}
    }
    const res = await axiosInstance.get(routes.machineGroupForServiceFeatures(), { params: params })
    pushToHistory(params)

    return(
      { rows: res.data.data, pageCount: res.data.meta.total_pages, rowCount: res.data.meta.total_count }
    )
  }

  const nameHtml = (row) => {
    return(
      <TableButton
        target="_blank"
        href={row?.original?.attributes?.link}
        className="btn-light-primary me-3"
        title={row?.original?.attributes?.name}
      />
    )
  }

  const machinesLinksHtml = (row) => {
    const machines_links = row?.original?.attributes?.machines_links || []

    if (machines_links.length === 0) return null

    return (
      <ExpandableList
        items={machines_links}
        renderItem={(machines_link) => (
          <a href={machines_link[1]}>{machines_link[0]}</a>
        )}
      />
    )
  }

  const servicesLinksHtml = (row) => {
    const services_links = row?.original?.attributes?.services_links || []

    if (services_links.length === 0) return null

    return (
      <ExpandableList
        items={services_links}
        renderItem={(services_link) => (
          <a href={services_link[1]}>{services_link[0]}</a>
        )}
      />
    )
  }

  const columns = useMemo(
    () => [
      {
        id: 'name',
        header: t('shared.machine_group'),
        accessorFn: row => row?.attributes?.name,
        enableSorting: true,
        size: 70,
        minSize: 70,
        cell: ({ row }) => {
          return nameHtml(row)
        },
      },
      {
        id: 'machines_links',
        accessorFn: row => row?.attributes?.machines_links,
        header: t('machine_group_for_service_features.machinery'),
        enableSorting: false,
        cell: ({ row }) => {
          return machinesLinksHtml(row)
        }
      },
      {
        id: 'services_links',
        accessorFn: row => row?.attributes?.services_links,
        header: t('machine_group_for_service_features.services_used'),
        enableSorting: false,
        cell: ({ row }) => {
          return servicesLinksHtml(row)
        }
      }
    ],
    [i18n.language]
  )

  return (
    <BaseTable
      columns={columns}
      getDataFn={getMachineGroupForServiceFeatures}
      tableToolbar={tableToolbar}
      initialSortDirection={'asc'}
    />
  )
}

export default Table;
