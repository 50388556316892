const locales = {
  en: {
    format: 'DD-MM-YY',
    applyLabel: 'Apply',
    cancelLabel: 'Cancel',
    fromLabel: 'From',
    toLabel: 'To',
    customRangeLabel: 'Custom',
    weekLabel: 'W',
    daysOfWeek: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
    monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    firstDay: 1,
  },
  de: {
    format: 'DD.MM.YY',
    applyLabel: 'Übernehmen',
    cancelLabel: 'Abbrechen',
    fromLabel: 'Von',
    toLabel: 'Bis',
    customRangeLabel: 'Benutzerdefiniert',
    weekLabel: 'W',
    daysOfWeek: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
    monthNames: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
    firstDay: 1,
  },
  pl: {
    format: 'DD-MM-YY',
    applyLabel: 'Zastosować',
    cancelLabel: 'Anulować',
    fromLabel: 'Od',
    toLabel: 'Do',
    customRangeLabel: 'Niestandardowy',
    weekLabel: 'W',
    daysOfWeek: ['Nd', 'Pn', 'Wt', 'Śr', 'Cz', 'Pt', 'So'],
    monthNames: ['Styczeń', 'Luty', 'Marzec', 'Kwiecień', 'Maj', 'Czerwiec', 'Lipiec', 'Sierpień', 'Wrzesień', 'Październik', 'Listopad', 'Grudzień'],
    firstDay: 1,
  },
};

export default locales
