import React, { useMemo } from 'react';
import BaseTable from '../Table';
import { isEmpty } from 'lodash';
import { axiosInstance, routes } from '../../utils/api_base';
import { pushToHistory } from '../../utils/base';
import { useTranslation } from 'react-i18next';

const Table = ({ tableToolbar, service_id }) => {
  const { t, i18n } = useTranslation()

  const getServiceFeatureChronicles = async (pagination, sorting, globalFilter, customFilters) => {
    const { pageIndex, pageSize } = pagination
    const sortQuery = sorting.map((attr) => {
      return attr.desc ? `${attr.id} desc` : attr.id
    })
    let params = { page: pageIndex + 1, limit: pageSize, sort: sortQuery[0], service_id: service_id }
    if (globalFilter) {
      params.query = globalFilter
    }
    if (!isEmpty(customFilters)) {
      params = { ...params, ...customFilters }
    }
    const res = await axiosInstance.get(routes.serviceFeatureChronicles(), { params: { ...params, serialize: "full" } })
    pushToHistory(params)

    return (
      { rows: res.data.data, pageCount: res.data.meta.total_pages, rowCount: res.data.meta.total_count }
    )
  }

  const columns = useMemo(
    () => [
      {
        id: 'time',
        header: t('time_expressions.time'),
        accessorFn: row => row?.attributes?.time,
        size: 100,
        minSize: 100,
        enableSorting: true,
        cell: row => row.getValue(),
      },
      {
        id: 'staff_long_name',
        accessorFn: row => row?.attributes?.staff_long_name,
        header: t('shared.employees'),
        enableSorting: true,
        cell: row => row.getValue(),
      },
      {
        id: 'description',
        accessorFn: row => row?.attributes?.description,
        header: t('shared.inputs'),
        enableSorting: true,
        cell: row => row.getValue(),
      },
      {
        id: 'service_long_name',
        accessorFn: row => row?.attributes?.service_long_name,
        header: t('shared.performance'),
        enableSorting: true,
        cell: row => row.getValue(),
      },
      {
        id: 'business_partner_long_name',
        accessorFn: row => row?.attributes?.business_partner_long_name,
        header: t('service_feature_chronicles.performance_of_the_customer'),
        enableSorting: true,
        cell: row => row.getValue(),
      },
      {
        id: 'offer_short_name_with_date',
        accessorFn: row => row?.attributes?.offer_short_name_with_date,
        header: t('service_feature_chronicles.performance_on_offer'),
        enableSorting: true,
        cell: row => row.getValue(),
      },
    ],
    [i18n.language]
  )

  return (
    <BaseTable
      getDataFn={getServiceFeatureChronicles}
      columns={columns}
      tableToolbar={tableToolbar}
    // filters={filters}
    />
  )
}

export default Table;
