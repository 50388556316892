import React from 'react'
import ModalHeader from "./ModalHeader";
import classNames from 'classnames'
import * as style from './index.module.scss';

const Modal = ({open, children, className, onClose, title}) => {

    return (
        open ? (
            <div className={classNames(style.modal, "modal")} tabIndex="-1">
                <div className={classNames("modal-dialog modal-dialog-centered", className)}>
                    <div className="modal-content modal-rounded">
                        <ModalHeader title={title} onClose={onClose}/>
                        {children}
                    </div>
                </div>
            </div>
        ) : null
    );
}

export default Modal
