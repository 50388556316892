import React, { useMemo } from 'react';
import BaseTable from '../../components/Table';
import { isEmpty } from 'lodash';
import { axiosInstance, routes } from '../../utils/api_base';
import { pushToHistory } from '../../utils/base';
import { useTranslation } from 'react-i18next';

const Table = ({ tableToolbar }) => {
  const { t, i18n } = useTranslation()

  const getArticleGroups = async (pagination, sorting, globalFilter, customFilters) => {
    const { pageIndex, pageSize } = pagination
    const sortQuery = sorting.map((attr) => {
      return attr.desc ? `${attr.id} desc` : attr.id
    })
    let params = { page: pageIndex + 1, limit: pageSize, sort: sortQuery[0] }
    if (globalFilter) {
      params.query = globalFilter
    }
    if (!isEmpty(customFilters)) {
      params = {...params, ...customFilters}
    }
    const res = await axiosInstance.get(routes.articleGroups(), { params: params })
    pushToHistory(params)

    return(
      { rows: res.data.data, pageCount: res.data.meta.total_pages, rowCount: res.data.meta.total_count }
    )
  }

  const columns = useMemo(
    () => [
      {
        id: 'name',
        header: t('shared.article_group'),
        accessorFn: row => row?.attributes?.name,
        enableSorting: true,
        cell: row => row.getValue()
      },
      {
        id: 'articles_count',
        accessorFn: row => row?.attributes?.articles_count,
        header: t('shared.article'),
        enableSorting: false,
        cell: row => row.getValue()
      }
    ],
    [i18n.language]
  )

  return (
    <BaseTable
      columns={columns}
      getDataFn={getArticleGroups}
      initialSortDirection={'asc'}
      tableToolbar={tableToolbar}
    />
  )
}

export default Table;
