import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as style from './index.module.css';
import consumer from '../../../utils/cable';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import ProgressBar from 'react-bootstrap/ProgressBar';

const Footer = ({root_url, user_id}) => {
  const { t } = useTranslation()

  useEffect(() => {
    consumer.subscriptions.create({
      channel: 'ExportChannel',
      user_id: user_id,
    },
    {
      connected: () => console.log('connected'),
      disconnected: () => console.log('disconnected'),
      received: data => processMessage(data),
    })
    return () => {
      consumer.disconnect()
    }
  }, [])

  const processMessage = (message) => {
    const ReactSwal = withReactContent(Swal)
    console.log({message})
    switch (message.state) {
      case 'start':
        ReactSwal.fire({
          title: t("export.start_exporting_message"),
          html: <ProgressBar now={0} label={`${0}%`} />,
          position: "top",
          width: 400,
          toast: true,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false
        })
        break
      case 'update':
        ReactSwal.update({
          title: t("export.exporting_message"),
          html: <ProgressBar now={message.payload.display_text} label={`${message.payload.display_text}%`} />,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
        })
        break
      case 'end':
        ReactSwal.fire({
          title: t("export.finish_exporting_message"),
          html: <ProgressBar now={100} label={`${100}%`} />,
          position: "top",
          width: 400,
          toast: true,
          showConfirmButton: true,
          confirmButtonText: t("shared.download"),
          allowOutsideClick: false,
          allowEscapeKey: false,
          timerProgressBar: true,
          timer: 4000,
        }).then((result) => {
          if (result.isConfirmed) {
            window.open(message.link, "_blank");
          }
        })
        break
      case 'error':
        ReactSwal.fire({
          title: message.payload.display_text,
          position: "top",
          width: 400,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
          timer: 2000,
        })
    }
  }

  return (
    <div id="kt_app_footer" className={style["app-footer"]}>
      <div className="app-container container-fluid d-flex flex-column flex-md-row flex-center flex-md-stack py-3">
        <div className="text-gray-900 order-2 order-md-1">
          <span className="text-muted fw-semibold me-1">{ new Date().getFullYear() }&copy;</span>
          <a href={root_url} target="_blank" className="text-gray-800 text-hover-primary">AgForce</a>
        </div>
        <ul className="menu menu-gray-600 menu-hover-primary fw-semibold order-1">
          <li className="menu-item">
            <a href={root_url} target="_blank" className="menu-link px-2">Über AgForce</a>
          </li>
          <li className="menu-item">
            <a href={root_url} target="_blank" className="menu-link px-2">{t("footer.support")}</a>
          </li>
        </ul>
      </div>
    </div>
  );
};

Footer.propTypes = {
  root_url: PropTypes.string.isRequired,
};

export default Footer
