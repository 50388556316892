import React from 'react';
import { useTranslation } from 'react-i18next';
import TableButton from '../../components/Table/TableButton';
import {ExportIcon, PlusIcon} from '../../components/Icons';
import Table from './Table';

const Vendors = ({filters}) => {
  const { t } = useTranslation()

  const tableToolbar = () => {
    return(
      <>
        <TableButton type="button" className="btn-light-primary me-3" icon={<ExportIcon />} title={t('shared.export')} />
        <TableButton
          className="btn-primary"
          icon={<PlusIcon />}
          title={t('business_partners.create_supplier')}
        />
      </>
    )
  }

  return (
    <>
      <Table filters={filters} tableToolbar={tableToolbar} />
    </>
  )
}

export default Vendors
