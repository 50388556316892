import React from "react";

const QuestionMark = ({ children, className = 'fs-2 '}) => {
  return (
    <i className={`ki-solid ki-question-2 text-dark ${className}`}>
      {children}
    </i>
  );
}

export default QuestionMark;
