import React, {useEffect, useState} from "react";
import {List} from "../../components/List";
import OrderItem from "./OrderItem/index";
import {axiosInstance, routes} from "../../utils/api_base";

const OrderSidebar = () => {
    const [orders, setOrders] = useState([])

    useEffect(() => {
        const params = {page: 1, limit: 10000, vague_date: "true"}
        axiosInstance.get(routes.orders(), {params: params}).then((response) => setOrders(response.data.data))
    }, []);

    return <div className={"card shadow-sm"}
                style={{position: 'absolute', top: 80, right: 10, width: 300, height: "calc(100% - 160px)"}}>
        <div className="card-header">
            <h3 className="card-title">Aufträge</h3>
            <div className="card-toolbar">
                <button type="button" className="btn btn-sm btn-light">
                    hinzufügen
                </button>
            </div>
        </div>
        <div className="card-body p-2">
            <List>
                {orders.map(order => <OrderItem key={order.id} order={order}></OrderItem>)}
            </List>
        </div>
    </div>
}

export default OrderSidebar
