import React from "react";
import classNames from 'classnames'

const TypeSwitch = ({type, onChangeType}) => {

    const types = [{value: 'day', label: 'Tag'}, {value: "week", label: 'Woche'}, {value: 'month', label: 'Monat'}]

    return (
        <div className="btn-group ms-3">
            {types.map(item => <label
                className={classNames("btn btn-outline btn-color-muted btn-active-primary btn-sm", item.value === type && "active")}>
                <button className="btn-check" onClick={() => onChangeType(item.value)}/>
                {item.label}
            </label>)}
        </div>
    )
}

export default TypeSwitch
