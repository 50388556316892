import React from 'react';
import Table from './Table';

const ServiceFeatures = () => {
  const tableToolbar = () => {
    return (
      <>
      </>
    )
  }

  return (
    <Table tableToolbar={tableToolbar} />
  )
}

export default ServiceFeatures;
