import React, {useEffect, useRef, useCallback, useMemo} from "react";
import 'select2/dist/js/select2.min.js';

const Select = ({url, params: customParams = {}, options = [], onChange, formatData = (data) => data, value = undefined, multiple = false, placeholder = '', disabled = false, allowBlank = false, className = ''}) => {
    const selectRef = useRef(null);

    const handleChange = useCallback(
        (e) => {
            if (onChange) {
                const selectedValue = multiple ? $(selectRef.current).val() : e.target.value;
                onChange(selectedValue);
            }
        },
        [onChange, multiple]
    );

    useEffect(() => {
        const $select = $(selectRef.current);

        console.log("Render: ", $select)
        if(allowBlank) options.unshift({text: '', id: ''})

        let settings = {
            data: options,
            allowClear: true,
            width: '100%',
            theme: "bootstrap5",
            placeholder,
            minimumResultsForSearch: 0,
            closeOnSelect: !multiple,
            selectionCssClass: ':all:',
            dropdownCssClass: 'bigdrop'}

        if(!!url) {
            settings = {...settings, ajax: {
                    cache: true,
                    url: url,
                    data: function (params) {
                        var query = {
                            query: params.term,
                            page: params.page || 1,
                            limit: 15,
                            ...customParams
                        }

                        // Query parameters will be ?search=[term]&page=[page]
                        return query;
                    },

                    processResults: function (data, params) {
                        params.page = params.page || 1;

                        return {
                            results: formatData(data.data),
                            pagination: {
                                more: params.page < data.meta.total_pages
                            }
                        };
                    }
                }}
        }

        $select.select2(settings);

        // Handle the change event
        $select.on('select2:close', handleChange);

        // Cleanup function to destroy Select2
        return () => {
            $select.off('select2:close');
            $select.select2('destroy');
        };
    }, [options, url, customParams, formatData, multiple, placeholder, handleChange, className]);

    const defaultValue = value === undefined ? (multiple ? [] : '') : value

    return (
        <select className={`form-select ${className}`} ref={selectRef} multiple={multiple} defaultValue={defaultValue} disabled={disabled}></select>
    );
}

export default Select
