import React, { useMemo, useState } from 'react';
import BaseTable from '../../components/Table';
import { isEmpty } from 'lodash';
import { axiosInstance, routes } from '../../utils/api_base';
import { pushToHistory } from '../../utils/base';
import { useTranslation } from 'react-i18next';

const Table = ({ tableToolbar, columnVisibility, year, month }) => {
  const { t, i18n } = useTranslation()
  const [columnNames, setColumnNames] = useState([]);

  const getRemunerationPayrolls = async (pagination, sorting, globalFilter, customFilters) => {
    const { pageIndex, pageSize } = pagination
    const sortQuery = sorting.map((attr) => {
      return attr.desc ? `${attr.id} desc` : attr.id
    })
    let params = { page: pageIndex + 1, limit: pageSize, sort: sortQuery[0], year: year, month: month }
    if (globalFilter) {
      params.query = globalFilter
    }
    if (!isEmpty(customFilters)) {
      params = {...params, ...customFilters}
    }
    const res = await axiosInstance.get(routes.remunerationPayrolls(), { params: params })
    pushToHistory(params)
    setColumnNames(res.data.meta.column_names)

    return(
      { rows: res.data.data, pageCount: res.data.meta.total_pages, rowCount: res.data.meta.total_count }
    )
  }

  const staffLinkHtml = (row) => {
    if (row?.original?.attributes?.staff_link) {
      return(
        <a href={row.original.attributes.staff_link}>
          {row.original.attributes.staff_name}
        </a>
      )
    }
  }

  const firstColumns = [
    {
      id: 'staff_number',
      header: t('remuneration_payrolls.number'),
      accessorFn: row => row?.attributes?.staff_number,
      enableSorting: false,
      cell: row => row.getValue()
    },
    {
      id: 'staff_link',
      header: t('remuneration_payrolls.name'),
      accessorFn: row => row?.attributes?.staff_link,
      enableSorting: false,
      cell: ({ row }) => {
        return staffLinkHtml(row)
      },
    },
    {
      id: 'cost_center_name',
      accessorFn: row => row?.attributes?.cost_center_name,
      header: t('remuneration_payrolls.cost_center'),
      enableSorting: false,
      cell: row => row.getValue()
    },
    {
      id: 'billable_hours',
      accessorFn: row => row?.attributes?.billable_hours,
      header: t('remuneration_payrolls.billable_hours'),
      enableSorting: false,
      cell: row => row.getValue()
    },
    {
      id: 'salary_wage',
      accessorFn: row => row?.attributes?.salary_wage,
      header: t('remuneration_payrolls.salary_wage'),
      enableSorting: false,
      cell: row => row.getValue()
    },
    {
      id: 'special_surcharge',
      accessorFn: row => row?.attributes?.special_surcharge,
      header: t('remuneration_payrolls.special_surcharge'),
      enableSorting: false,
      cell: row => row.getValue()
    }
  ]

  const dynamicColumns = columnNames.map(name => ({
    id: name,
    accessorFn: row => {
      const value = row?.attributes?.wage_benefit_rules?.[name]
      return (value == 0 ? null : value)
    },
    header: name,
    enableSorting: false,
    cell: row => row.getValue()
  }))

  const lastColumns = [
    {
      id: 'comment_for_office',
      accessorFn: row => row?.attributes?.comment_for_office,
      header: t('remuneration_payrolls.comment_for_office'),
      enableSorting: false,
      cell: row => row.getValue()
    },
    {
      id: 'company_name',
      accessorFn: row => row?.attributes?.company_name,
      header: t('remuneration_payrolls.company'),
      enableSorting: false,
      cell: row => row.getValue()
    }
  ]

  const columns = useMemo(
    () => [...firstColumns, ...dynamicColumns, ...lastColumns],
    [i18n.language, columnNames]
  )

  return (
    <BaseTable
      columns={columns}
      getDataFn={getRemunerationPayrolls}
      tableToolbar={tableToolbar}
      columnVisibility={columnVisibility}
      trigger={year + month}
    />
  )
}

export default Table;
