import React, { useMemo } from 'react';
import BaseTable from '../../components/Table';
import { isEmpty } from 'lodash';
import { axiosInstance, routes } from '../../utils/api_base';
import { pushToHistory } from '../../utils/base';
import { useTranslation } from 'react-i18next';

const Table = ({ tableToolbar, filters, onApplyFilter }) => {
  const { t, i18n } = useTranslation()

  const getStaffOvertime = async (pagination, sorting, globalFilter, customFilters, ) => {
    let params = { }
    if (globalFilter) {
      params.query = globalFilter
    }
    if (!isEmpty(customFilters)) {
      params = { ...params, ...customFilters }
    }

    if (params.staffs && !Array.isArray(params.staffs)) {
      params.staffs = [params.staffs]
    }

    const res = await axiosInstance.get(routes.staffOvertime(), { params: params })
    pushToHistory(params)
    onApplyFilter(params)
    return (
      { rows: res.data.data }
    )
  }

  const staffRow = (row) => {
    return (
      <a href={row?.original?.attributes?.staff_link}>
        {row?.original?.attributes?.long_name}
      </a>
    )
  }

  const hourRow = (row) => {
    return (
      <a href={row?.original?.attributes?.multi_booking_link}>
        {row?.original?.attributes?.total_quantity}
      </a>
    )
  }

  const columns = useMemo(
    () => [
      {
        id: 'name',
        header: t('shared.name'),
        accessorFn: row => row?.attributes?.long_name,
        enableSorting: false,
        cell: ({ row }) => {
          return staffRow(row)
        },
      },
      {
        id: 'day',
        accessorFn: row => row?.attributes?.date_quantity,
        header: t('time_expressions.day'),
        enableSorting: false,
        cell: row => row.getValue()
      },
      {
        id: 'hours',
        accessorFn: row => row?.attributes?.total_quantity,
        header: t('time_expressions.hours'),
        enableSorting: false,
        cell: ({ row }) => {
          return hourRow(row)
        },
      }
    ],
    [i18n.language]
  )

  return (
    <BaseTable
      columns={columns}
      getDataFn={getStaffOvertime}
      initialSortDirection={'asc'}
      tableToolbar={tableToolbar}
      enablePagination={false}
      enableSorting={false}
      showSearchInput={false}
      filters={filters}
    />
  )
}

export default Table;
