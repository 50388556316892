import PropTypes from 'prop-types';
import React, {Fragment, useMemo, useState} from 'react';
import { axiosInstance, routes } from '../../utils/api_base';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames'
import * as style from './index.module.css';
import { pushToHistory } from '../../utils/base';
import BaseTable from '../Table';
import TableButton from '../Table/TableButton';
import {CheckCircleIcon, CrossSquareIcon, EditIcon, ExportIcon, FilterIcon, PlusIcon, WatchIcon} from '../Icons';
import StatusLabel from '../../core/StatusLabel';
import Toastr from '../Toastr';
import { isEmpty } from 'lodash';

const Bills = ({email_enabled, reminder_enabled, filters}) => {
  const { t, i18n } = useTranslation()
  const columnVisibility = { email: email_enabled, reminder_enabled: reminder_enabled }
  const [trigger, setTrigger] = useState(true)

  const getBills = async (pagination, sorting, globalFilter, customFilters) => {
    const { pageIndex, pageSize } = pagination
    const sortQuery = sorting.map((attr) => {
      return attr.desc ? `${attr.id} desc` : attr.id
    })
    let params = { page: pageIndex + 1, limit: pageSize, sort: sortQuery[0] }
    if (globalFilter) {
      params.query = globalFilter
    }
    if (!isEmpty(customFilters)) {
      params = {...params, ...customFilters}
    }
    const res = await axiosInstance.get(routes.bills(), { params: params })
    pushToHistory(params)

    return(
      { rows: res.data.data, pageCount: res.data.meta.total_pages, rowCount: res.data.meta.total_count }
    )
  }

  const createDunning = (billId, dunningLevel) => {
    axiosInstance.post(routes.dunnings(), {
      bill_id: billId, dunning_level: dunningLevel
    })
    .then(resp => {
      Toastr({message: t('dunning.created'), options: { showDuration: 2000}})
      setTrigger(!trigger) // Dirty hack to refresh the table so new dunning appear in table
     })
    .catch(error => {
      Toastr({
        type: 'error',
        message: error.response?.data?.error || t('critical_error')
      })
    })
  }

  const tableToolbar = () => {
    return(
      <>
        {/*<TableButton type="button" className="btn-light-primary me-3" icon={<FilterIcon />} title={t('shared.filter')} />*/}
        <TableButton type="button" className="btn-light-primary me-3" icon={<ExportIcon />} title={t('shared.export')} />
        <TableButton className="btn-primary" icon={<PlusIcon />} title={t('bills_table.create_bill')}/>
      </>
    )
  }

  const letterLabel = (row) => {
    const status = row.original.attributes.letter?.attributes?.status
    switch(status) {
      case "sent":
        return <CheckCircleIcon className="fs-2x text-success"/>
      case "draft":
        return <EditIcon className="fs-2x text-primary"/>
      case "failed":
        return <CrossSquareIcon className="fs-2x text-danger"/>
      case "sending":
        return <WatchIcon className="fs-2x text-warning"/>
    }
  }

  const codeHtml = (row) => {
    return(
      <TableButton
        target="_blank"
        href={row?.original?.attributes?.link}
        className="btn-light-primary me-3"
        title={row?.original?.attributes?.name}
      />
    )
  }

  const deadlineHtml = (row) => {
    const { payment_day, booked_status, paid_status, fully_settled, new_dunning_level, payment_paid_date } = row.original.attributes
    const showPaymentDay = (payment_day && booked_status) || (paid_status && !fully_settled)
    if(showPaymentDay) {
      if (new_dunning_level !== false) {
        return <i style={{color: 'red'}}>{payment_day}</i>
      } else {
        return payment_day
      }
    }
    if(paid_status && fully_settled) {
      return <i style={{color: 'green'}}>{t('bills_table.paid_on')} {payment_paid_date}</i>
    }
  }

  const dunningsHtml = (row) => {
    const dunnings = row.original.attributes.dunnings
    return(
      <>
        { dunningsList(dunnings) }
        { newDunningButton(row.original.attributes) }
      </>
    )
  }

  const dunningsList = (dunnings) => {
    return(
      dunnings.map((dunning, index) => {
        return(
          <Fragment key={index}>
          <span className="badge badge-light-warning">
            <a href={dunning.attributes.link} target="_blank">{dunningName(dunning.attributes.level)}</a>
          </span>
            <br />
          </Fragment>
        )
      })
    )
  }

  // TODO: Rework with submit?
  const newDunningButton = (bill) => {
    if(bill.new_dunning_level) {
      return(
        <>
          <br />
          <TableButton
            onClick={() => createDunning(bill.id, bill.new_dunning_level)}
            className={classNames("btn-light-info btn-sm", style['small-button'])}
            icon={<PlusIcon />}
            title={dunningName(bill.new_dunning_level)}
          />
        </>
      )
    }
  }

  const dunningName = (level) => {
    switch(level) {
      case 0:
        return t('dunning.payment_reminder')
      case 1:
        return t('dunning.first_reminder')
      case 2:
        return t('dunning.second_reminder')
      case 3:
        return t('dunning.third_reminder')
    }
  }

  const columns = useMemo(
    () => [
      {
        id: 'code',
        header: t('shared.bill'),
        accessorFn: row => row?.attributes?.name,
        size: 70,
        minSize: 70,
        cell: ({ row }) => {
          return codeHtml(row)
        },
      },
      {
        id: 'customer',
        accessorFn: row => row?.attributes?.business_partner_name,
        header: t('shared.customer'),
        cell: row => row.getValue(),
      },
      {
        accessorFn: row => <div dangerouslySetInnerHTML={{ __html: row?.attributes?.description }} />,
        header: t('shared.performance'),
        cell: row => row.getValue(),
      },
      {
        id: 'date',
        accessorFn: row => row?.attributes?.bill_date,
        header: t('shared.date'),
        cell: row => row.getValue(),
      },
      {
        id: 'status',
        accessorFn: row => row?.attributes?.status,
        header: t('shared.status'),
        cell: ({ row }) => {
          return <StatusLabel code={row.original.attributes?.status_code?.toLowerCase()} />
        },
      },
      {
        accessorFn: row => row?.attributes?.letter?.attributes?.id,
        header: t('bills_table.sent'),
        cell: ({ row }) => {
          return letterLabel(row)
        },
      },
      {
        id: 'gross',
        accessorFn: row => row?.attributes?.gross_amount,
        header: t('shared.gross'),
        cell: row => row.getValue(),
      },
      {
        id: 'deadline',
        accessorFn: row => row?.attributes?.gross_amount,
        header: t('shared.deadline'),
        cell: ({ row }) => {
          return deadlineHtml(row)
        },
      },
      {
        id: 'duning',
        accessorFn: row => row?.attributes?.dunnings?.length, // TODO: find examples
        header: t('shared.dunning'),
        cell: ({ row }) => {
          return dunningsHtml(row)
        },
      },
    ],
    [i18n.language]
  )

  return (
    <BaseTable
      columns={columns}
      columnVisibility={columnVisibility}
      getDataFn={getBills}
      tableToolbar={tableToolbar}
      trigger={trigger}
      filters={filters}
    />
  )
}

Bills.propTypes = {
  email_enabled: PropTypes.bool.isRequired,
  reminder_enabled: PropTypes.bool.isRequired,
};

export default Bills
