import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Toastr from '../../components/Toastr';
import { axiosInstance, routes } from '../../utils/api_base';
import { Form, Field } from 'react-final-form'
import TableButton from '../../components/Table/TableButton';

const Create = ({ machine = null, onClose }) => {
  const { t } = useTranslation();
  const [errors, setErrors] = useState([]);

  const invalidInput = (message) => {
    return (
      <div className="invalid-feedback">
        {message}
      </div>
    )
  };

  const onSubmit = async values => {
    const data = {
      machine_group_for_service_feature: {
        name: values.name || '',
      },
      machine: machine
    }

    axiosInstance.post(routes.machineGroupForServiceFeatures(), data)
      .then(response => {
        Toastr({
          title: 'Success!',
          message: response.data.message,
          options: { closeButton: true }
        })

        onClose()

        if (response.data.redirect_url) {
          window.location.href = response.data.redirect_url;
        }

      })
      .catch(error => {
        setErrors(error.response.data.error);
        Toastr({
          type: 'error',
          message: 'Error!',
          options: { closeButton: true }
        });
      })
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        name: ''
      }}
      render={({ handleSubmit, submitting, pristine }) => (
        <form onSubmit={handleSubmit}>
          <div className="container">
            <div className="row mb-3">
              <div className="col">
                <label className='required'>{t('shared.name')}</label>
                <Field
                  name="name"
                  required="required"
                  component="input"
                  type="text"
                  className={`form-control ${errors["name"] ? "is-invalid" : ""}`}
                />
                {errors["name"] ? invalidInput(errors["name"]) : null}
              </div>
            </div>
            <div className="modal-footer">
              <TableButton
                className="btn btn-light btn-active-light-primary fw-semibold me-2 px-6"
                type="button"
                onClick={onClose}
                title={t('cancel')}
              />
              <TableButton
                className="btn btn-primary fw-semibold px-6"
                type="submit"
                title={t('create')}
                disabled={submitting || pristine}
              />
            </div>
          </div>
        </form>
      )}
    />
  )
}

export default Create
