import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Table from './Table';

const ArticlePurchases = ({ articleId }) => {
  const tableToolbar = () => { return (<></>) }

  return (
    <Table tableToolbar={tableToolbar} articleId={articleId} />
  )
}

export default ArticlePurchases;
