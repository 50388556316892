import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { axiosInstance, routes } from '../../utils/api_base';
import BaseTable from '../Table';
import { pushToHistory } from '../../utils/base';
import TableButton from '../Table/TableButton';
import { ExportIcon, FilterIcon, PlusIcon } from '../Icons';
import StatusLabel from '../../core/StatusLabel';
import Modal from '../../components/Modal';
import Create from './Create';

const Sites = ({ clientSpecificNumber }) => {
  const { t, i18n } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);

  const getSites = async (pagination, sorting, globalFilter) => {
    const { pageIndex, pageSize } = pagination
    const sortQuery = sorting.map((attr) => {
      return attr.desc ? `${attr.id} desc` : attr.id
    })
    const params = { page: pageIndex + 1, limit: pageSize, sort: sortQuery[0] }
    if (globalFilter) {
      params.query = globalFilter
    }

    const res = await axiosInstance.get(routes.sites(), { params: params })

    pushToHistory(params)

    return (
      { rows: res.data.data, pageCount: res.data.meta.total_pages, rowCount: res.data.meta.total_count }
    )
  }

  const tableToolbar = () => {
    return (
      <>
        <TableButton type="button" className="btn-light-primary me-3" icon={<FilterIcon />} title={t('shared.filter')} />
        <TableButton type="button" className="btn-light-primary me-3" icon={<ExportIcon />} title={t('shared.export')} />
        <TableButton
          className="btn-primary"
          onClick={() => setModalOpen(true)}
          icon={<PlusIcon />}
          title={t('new')}
        />
      </>
    )
  }

  const codeHtml = (row) => {
    return (
      <TableButton
        target="_blank"
        href={row?.original?.attributes?.link}
        className="btn-light-primary me-3 w-100"
        title={row?.original?.attributes?.long_name}
      />
    )
  }

  const columns = useMemo(
    () => [
      {
        id: 'code', // TODO: find examples for bills and clarify presentation
        header: "ID",
        accessorFn: row => row?.attributes?.id,
        size: 70,
        minSize: 70,
        cell: ({ row }) => {
          return codeHtml(row)
        },
      },
      {
        id: 'customer',
        accessorFn: row => row?.attributes?.business_partner_name,
        header: t('shared.customer'),
        cell: row => row.getValue(),
      },

      {
        id: 'orders',
        header: t('shared.assignments'),
        accessorFn: row => row?.attributes?.orders_count,
        cell: row => row.getValue(),
      },
      {
        id: 'location',
        header: t('shared.location'),
        accessorKey: 'attributes.bill_address_city',
        cell: row => row.getValue(),
      },
      {
        id: 'start_date',
        header: t('shared.from'),
        accessorKey: 'attributes.start_date',
        cell: row => row.getValue(),
      },
      {
        id: 'end_date',
        header: t('shared.until'),
        accessorKey: 'attributes.end_date',
        cell: row => row.getValue(),
      },
    ],
    [i18n.language]
  )

  return (
    <>
      <Modal className="modal fade show" open={modalOpen} onClose={() => setModalOpen(false)} title={t('new_site')}>
        <Create onClose={() => setModalOpen(false)} clientSpecificNumber={clientSpecificNumber} />
      </Modal>
      <BaseTable columns={columns} columnVisibility={{}} getDataFn={getSites} tableToolbar={tableToolbar} />
    </>
  )
}

export default Sites
