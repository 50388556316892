import React, { useEffect, useState } from "react";
import {List} from "../../components/List";
import StaffItem from "./StaffItem/index";

const StaffSidebar = () => {
    const [staffs, setStaffs] = useState([])

    useEffect(() => {
        // axiosInstance.get(routes.staffs(), { active: true }).then(response => setStaffs(response.data))
    }, []);

    return <div className={"card shadow-sm"}
                style={{position: 'absolute', top: 80, right: 10, width: 300, height: "calc(100% - 160px)"}}>
        <div className="card-header">
            <h3 className="card-title">Mitarbeiter</h3>
        </div>
        <div className="card-body p-2" style={{overvlowY: 'scroll', height: '100%', position: 'relative'}}>
            <List style={{ overflowY: 'scroll', height: '90%' }}>
                { staffs.map(staff => <StaffItem key={staff.id} staff={staff}></StaffItem>)}
            </List>
        </div>
    </div>
}

export default StaffSidebar
