import React, { useMemo, useEffect } from 'react';
import BaseTable from '../../components/Table';
import { isEmpty } from 'lodash';
import { axiosInstance, routes } from '../../utils/api_base';
import { pushToHistory } from '../../utils/base';
import { useTranslation } from 'react-i18next';
import TableButton from '../../components/Table/TableButton';
import { ArrowsCircleIcon, CloudDownloadIcon, TrashIcon } from '../../components/Icons';
import Toastr from '../../components/Toastr';

const Table = ({ tableToolbar }) => {
  const { t, i18n } = useTranslation()

  const getDocumentOfBookingReports = async (pagination, sorting, globalFilter, customFilters) => {
    const { pageIndex, pageSize } = pagination
    const sortQuery = sorting.map((attr) => {
      return attr.desc ? `${attr.id} desc` : attr.id
    })
    let params = { page: pageIndex + 1, limit: pageSize, sort: sortQuery[0] }
    if (globalFilter) {
      params.query = globalFilter
    }
    if (!isEmpty(customFilters)) {
      params = {...params, ...customFilters}
    }
    const res = await axiosInstance.get(routes.documentOfBookingReports(), { params: params })
    pushToHistory(params)

    return(
      { rows: res.data.data, pageCount: res.data.meta.total_pages, rowCount: res.data.meta.total_count }
    )
  }

  const deleteDocumentOfBookingReports = (id) => {
    let result = confirm(t("shared.confirmation_question"));

    if (result) {
      axiosInstance.delete(routes.documentOfBookingReport(id))
        .then(response => {
          Toastr({message: response.data?.message})
          window.location.reload()
         })
        .catch(response => {
          Toastr({type: 'error', message: response.data?.errors})
        })
    }
  }

  const bookingMaskLinkHtml = (row) => {
    if (row?.original?.attributes?.link) {
      return(
        <TableButton
          href={row.original.attributes.link}
          className="btn-light-primary me-3"
          title={t('document_of_booking_reports.booking_mask')}
        />
      )
    }
  }

  const actionsHtml = (row) => {
    return(
      <>
        {
          row?.original?.attributes?.is_report_present &&
            <span title={t('document_of_booking_reports.download')}>
              <TableButton
                type="button"
                className="btn-light-primary me-3"
                size="small"
                icon={<CloudDownloadIcon className="fs-2" />}
                title=""
                href={routes.downloadDocumentOfBookingReports(row?.original?.attributes?.id)}
              />
            </span>
        }
        <span title={t('document_of_booking_reports.refresh')}>
          <TableButton
            type="button"
            className="btn-light-primary me-3"
            size="small"
            icon={<ArrowsCircleIcon className="fs-2" />}
            title=""
            href={routes.refreshDocumentOfBookingReports(row?.original?.attributes?.id)}
          />
        </span>
        <span title={t('document_of_booking_reports.delete')}>
          <TableButton
            type="button"
            className="btn-light-primary me-3"
            size="small"
            icon={<TrashIcon className="fs-2 text-danger" />}
            title=""
            onClick={() => deleteDocumentOfBookingReports(row?.original?.attributes?.id)}
          />
        </span>
      </>
    )
  }

  const columns = useMemo(
    () => [
      {
        id: 'name',
        header: t('document_of_booking_reports.report'),
        accessorFn: row => row?.attributes?.name,
        cell: row => row.getValue()
      },
      {
        id: 'lines',
        header: t('document_of_booking_reports.number_of_lines'),
        accessorFn: row => row?.attributes?.lines,
        cell: row => row.getValue()
      },
      {
        id: 'link',
        header: t('document_of_booking_reports.booking_mask'),
        accessorFn: row => row?.attributes?.link,
        enableSorting: false,
        cell: ({ row }) => {
          return bookingMaskLinkHtml(row)
        },
      },
      {
        id: 'last_execution_date',
        header: t('document_of_booking_reports.last_execution_date'),
        accessorFn: row => row?.attributes?.updated_at,
        enableSorting: true,
        cell: row => row.getValue()
      },
      {
        id: 'actions',
        header: '',
        enableSorting: false,
        cell: ({ row }) => {
          return actionsHtml(row)
        },
      },
    ],
    [i18n.language]
  )

  return (
    <BaseTable
      columns={columns}
      getDataFn={getDocumentOfBookingReports}
      sortBy='last_execution_date'
      tableToolbar={tableToolbar}
    />
  )
}

export default Table;
