import React from 'react'
import { withTranslation } from 'react-i18next';
import Dropdown from 'react-bootstrap/Dropdown';
import EnFlag from '../../../images/en.svg'
import DeFlag from '../../../images/de.svg';
import PlFlag from '../../../images/pl.svg';
import { find } from 'lodash';
import { axiosInstance, routes } from '../../../utils/api_base';
import Toastr from '../../Toastr';
import classNames from 'classnames'
import * as styles from './index.module.css'

const Languages = ({ i18n }) => {

  const changeLanguage = (locale) => {
    axiosInstance
      .put(
        routes.updateLocale(),
        { locale: locale }
      )
      .then(response => {
        i18n.changeLanguage(locale)
        window.location.reload()
      })
      .catch(error => {
        console.log('error', error)
        Toastr({
          type: 'error',
          message: 'The language was not changed'
        })
      })
  }

  const options = [
    { locale: "en", flag: EnFlag, title: i18n.t('english') },
    { locale: "de", flag: DeFlag, title: i18n.t('german') },
    { locale: "pl", flag: PlFlag, title: i18n.t('polish') },
  ]

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
      className="btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px">
      <span
        className="symbol symbol-20px"
        ref={ref}
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }}
      >
        <img src={find(options, { locale: i18n.resolvedLanguage }).flag} />
      </span>
    </div>
  ))

  const CustomMenu = React.forwardRef(
    ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {

      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          {options.map((option, index) => {
            return (
              <div key={index} className="menu-item px-5 py-2">
                <span
                  className={classNames("symbol symbol-20px me-4", styles["menu-item"])}
                  onClick={() => changeLanguage(option.locale)}
                >
                  <img src={option.flag} /> {option.title}
                </span>
              </div>
            )
          })}
        </div>
      )
    }
  )

  return (
    <Dropdown>
      <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components" />
      <Dropdown.Menu
        as={CustomMenu}
        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-semibold py-4 fs-6 w-150px"
      />
    </Dropdown>
  )
}

export default withTranslation()(Languages)
