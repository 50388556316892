import PropTypes from 'prop-types';
import React from 'react'
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

export const statusCodeStyle = (code) => {
  switch (code) {
    case 'new':
    case 'paid':
      return 'badge-success'
    case 'sent':
    case 'partly_paid':
    case 'inkasso':
      return 'badge-light-success'
    case 'booked':
    case 'booked_offer':
    case 'reversed':
    case 'canceled':
      return 'badge-light-danger'
    case 'inprocess':
      return 'badge-light-primary'
    case 'pending':
      return 'badge-light-warning'

    default: return '';
  }
}
const StatusLabel = ({code}) => {
  const { t } = useTranslation()
  return <span className={classNames('badge', statusCodeStyle(code))}>{t(`shared.statuses.${code}`)}</span>
}

// TODO: add one of validation
StatusLabel.propTypes = {
  code: PropTypes.string.isRequired,
}

export default StatusLabel
