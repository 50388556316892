import React from "react";
import StaffOrder from "../StaffOrder";
import StaffOrderForm from "../StaffOrder/Form";


const StaffOrders = ({ staffOrders, staffs, services, time, staffId }) => {

    return <div className="mh-375px scroll-y me-n7 pe-7">
        <StaffOrderForm time={time} staffs={staffs} services={services} staffId={staffId} />
        { staffOrders.map(staffOrder => <StaffOrder staffOrder={staffOrder} onEdit={console.log} /> )}
    </div>
}

export default StaffOrders
