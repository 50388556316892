import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Toastr from '../Toastr';
import {axiosInstance, routes} from '../../utils/api_base';
import Checkbox from '../Checkbox';
import { Form, Field } from 'react-final-form'
import TableButton from '../Table/TableButton';
import AsyncSelectAdapter from '../Form/AsyncSelectAdapter';
import { isRequired, minLength, composeValidators } from '../Form/validations';
import debounce from "lodash/debounce";
import ErrorField from '../Form/ErrorField';
import { mapValues } from 'lodash';
import { FORM_ERROR } from 'final-form'

const Create = ({onClose}) => {
  const { t } = useTranslation();

  // Close modal on pressing esc
  useEffect(() => {
    const close = (e) => {
      if(e.keyCode === 27){
        onClose()
      }
    }
    window.addEventListener('keydown', close)
    return () => window.removeEventListener('keydown', close)
  },[])

  const onSubmit = async values => {
    const data = {
      machine_detail: {
        manufacturer: values?.manufacturer,
        machine_group_id: values?.machine_group?.value,
        rental: values.rentalMachine
      },
      machine: {
        client_specific_number: values?.client_specific_number,
        name: values?.name
      }
    };

    return axiosInstance.post(routes.machines(), data)
      .then(response => {
        Toastr({
          title: 'Success!',
          message: response.data.message,
          options: { closeButton: true }
        })
        onClose()
      })
      .catch(error => {
        const errorData = error.response.data
        if (errorData instanceof Array) {
          return mapValues(errorData.error, (val) => (val.join(', ')))
        } else {
          return { [FORM_ERROR]: error.message }
        }
      })
  }

  const getMachineGroups = (inputText) => {
    let params = { page: 1, limit: 20, sort: 'name', query: inputText }

    return axiosInstance.get(routes.machineGroups(), { params: params })
      .then((response) => {
        return response.data.data.map((mg) => ({
          value: mg.id,
          label: mg.attributes.name,
        }));
      })
      .catch((error) => {
        console.log('ERROR', error)
      });
  };

  const loadMGOptions = useCallback(
    debounce((inputText, callback) => {
      getMachineGroups(inputText).then((options) => callback(options));
    }, 1000), [])

  return(
    <Form
      onSubmit={onSubmit}
      initialValues={{
        rentalMachine: false
      }}
      validate={values => {
        let errors = {}
        errors.machine_group = isRequired(values.machine_group)
        errors.name = composeValidators(isRequired, minLength(2))(values.name)
        return errors
      }}
      render={({ submitError, handleSubmit, submitting, pristine }) => (
        <form onSubmit={handleSubmit}>
          <>
            <div className="row mb-3">
              <div className="col">
                <label>{t('shared.number')}</label>
                <Field
                  name="client_specific_number"
                  component="input"
                  type="text"
                  className="form-control"
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col">
                <label>{t('shared.manufacturer')}</label>
                <Field
                  name="manufacturer"
                  component="input"
                  type="text"
                  className="form-control"
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col">
                <label className='required'>{t('shared.name')}</label>
                <Field
                  name="name"
                  component="input"
                  type="text"
                  className="form-control"
                />
                <ErrorField name="name" />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col">
                <label className='required'>{t('shared.machine_group')}</label>
                <Field
                  name='machine_group'
                  component={AsyncSelectAdapter}
                  loadOptions={loadMGOptions}
                  placeholder={t('shared.machine_group')}
                  isClearable={true}
                >
                </Field>
                <ErrorField name="machine_group" />
              </div>
            </div>
            <div className="mb-3">
              <Field
                name="rentalMachine"
                component={Checkbox}
                type="checkbox"
                size="sm"
                label={t('shared.rental_machine')}
              />
            </div>
            {submitError && <div className="form-error-message">{submitError}</div>}
            <div className="modal-footer">
              <TableButton
                className="btn btn-primary fw-semibold px-6"
                type="submit"
                title={"Create"}
                disabled={submitting || pristine}
              />
              <TableButton
                className="btn btn-light btn-active-light-primary fw-semibold me-2 px-6"
                type="button"
                onClick={onClose}
                title={"Cancel"}
              />
            </div>
          </>
        </form>
      )}
    />
  )
}

export default Create
