import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

const JobProgressBar = ({ jobId }) => {
  const [status, setStatus] = useState('');
  const [progress, setProgress] = useState(20);
  const [alertMessage, setAlertMessage] = useState('');
  const [returnTo, setReturnTo] = useState('');
  const [intervalId, setIntervalId] = useState(null);
  const { t } = useTranslation()

  useEffect(() => {
    if (!jobId) {
      return;
    }

    const fetchJobStatus = async () => {
      try {
        const response = await axios.get(`/jobs/${jobId || defID}`);
        const job = response.data;
        const jobStatus = `shared.statuses.background.${job.status}`;

        if (job.status === 'retrying') {
          setStatus(jobStatus);
          setProgress(10);
        } else if (job.status === 'queued') {
          setStatus(jobStatus);
          setProgress(50);
        } else if (job.status === 'complete') {
          setStatus(jobStatus);
          setProgress(100);
          clearInterval(intervalId);
          if (job.alert_message) {
            setAlertMessage(job.alert_message);
          } else if (job.return_to) {
            setReturnTo(job.return_to);
          }
        } else {
          setStatus(jobStatus);
          setProgress(job.at);
        }
      } catch (error) {
        console.error('Error fetching job status:', error);
      }
    };
    
    fetchJobStatus();
    const id = setInterval(fetchJobStatus, 3000);
    setIntervalId(id);

    return () => {
      clearInterval(id);
    };
  }, [jobId]);

  useEffect(() => {
    if (returnTo) {
      clearInterval(intervalId);
      window.location.href = returnTo;
    }
  }, [returnTo]);

  return (
    <div>
      {alertMessage && <div className="alert alert-success alert-block">{alertMessage}</div>}
      <div className="progress bg-gray-300 mt-3">
        <div className="progress-bar progress-bar-striped bg-success progress-bar-animated"
          role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style={{ width: `${progress}%` }}>{t(`${status}`)} {progress || 20 }%
        </div>
      </div>
    </div>
  );
};

export default JobProgressBar;
