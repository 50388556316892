import React, {useState, useEffect, useMemo, useCallback} from 'react'
import Modal from "../../components/Modal";
import Input from "../../components/Input";
import Select from "../../components/Select";
import {axiosInstance, routes} from "../../utils/api_base";
import Textarea from "../../components/Textarea";
import ModalFooter from "../../components/Modal/ModalFooter";
import ModalContent from "../../components/Modal/ModalContent";
import Button from "../../components/Button";
import moment from 'moment'
import StaffOrders from "./StaffOrders";
import DateRangePicker from "../DateRangePicker";
import {useTranslation} from 'react-i18next';

const CreateOrderModal = ({open, onClose, businessPartners, services, units, time, staffId, staffs }) => {
    const {t, i18n} = useTranslation()

    const [state, setState] = useState({
        time,
        services: [],
        unitId: [],
        fields: [],
        quantity: 0,
        comment: '',
        startTime: moment(time),
        endTime: moment(time).add(5, 'hours'),
        businessPartnerId: null
    })

    const [fields, setFields] = useState([])

    useEffect(() => {
        if (!state.businessPartnerId) return

        getFields({pageIndex: 0, pageSize: 10}, state.businessPartnerId)
            .then(result => {
                setFields(result.rows)
            })
    }, [state.businessPartnerId]);

    const getFields = async (pagination, businessPartnerId) => {
        const {pageIndex, pageSize} = pagination
        const params = {page: pageIndex + 1, limit: pageSize, business_partner_id: businessPartnerId}
        const res = await axiosInstance.get(
            routes.fields(),
            {
                params: params
            }
        )

        return (
            {rows: res.data.data, pageCount: res.data.meta.total_pages, rowCount: res.data.meta.total_count}
        )
    }

    const onSubmit = () => {
        const params = {
            quantity_ordered_unit_id: state.unitId,
            quantity_ordered: state.quantity,
            business_partner_id: state.businessPartnerId,
            service_ids: state.services,
            start_time: state.startTime,
            end_time: state.endTime,
            comment: state.comment,
            field_ids: []
        }

        axiosInstance.post(routes.orders(), params)
    }

    const [staffOrders, setStaffOrders] = useState([])

    const serviceOptions = useMemo(() => services.map((entity) => ({
        id: entity.id,
        text: entity.attributes.long_name
    })), [services]);

    const handleServiceChange = useCallback((values) => {
        setState((state) => ({ ...state, services: values }));
    }, []);

    console.count("Render Form")

    return (
        <Modal open={open} onClose={onClose} title={"Neuer Auftrag"} className={'modal-fullscreen p-9'}>
            <ModalContent>
                <div className="scroll-y me-n7 pe-7" id="kt_modal_new_address_scroll" data-kt-scroll="true">
                    <div className="row mb-5">
                        <div className="col-md-6 fv-row">
                            <h3>Informationen</h3>

                            <div className="row mt-5">
                                <div className={"col-6 fv-row"}>
                                    <label className="required fs-5 fw-semibold mb-2">Kunde</label>
                                    <Select options={businessPartners.map((entity) => ({
                                        id:  entity.id,
                                        text: entity.attributes.long_name
                                    }))}
                                            onChange={(value) => setState((state) => ({
                                                ...state,
                                                businessPartnerId: value
                                            }))}
                                            multiple={false} placeholder={'Kunde'}></Select>
                                </div>

                                <div className={"col-6 fv-row"}>
                                    <label className="required fs-5 fw-semibold mb-2">Dienstleistung</label>
                                    <Select options={serviceOptions}
                                            onChange={handleServiceChange}
                                            multiple={true} placeholder={'Dienstleistung'}></Select>
                                </div>
                            </div>

                            <div className={"row mt-2"}>
                                <div className={"col-12 fv-row"}>
                                    <label className="required fs-5 fw-semibold mb-2">Zeitraum</label>
                                    <DateRangePicker
                                        placeholder=''
                                        language={i18n.language}
                                        classList="cursor-pointer form-control text-left"
                                        options={{
                                            timePicker: true,
                                            showDropdowns: true,
                                            startDate: state.startTime,
                                            endDate: state.endTime,
                                            onChange: (startTime, endTime) => setState((state) => ({ ...state, startTime, endTime })),
                                            autoApply: false
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className={"col-6 fw-row"}>
                                    <Input placeholder={"Menge"} onChange={(value) => setState((state) => ({
                                        ...state,
                                        quantity: value
                                    }))}></Input>
                                </div>
                                <div className={"col-6 fv-row"}>
                                    <label className="fs-5 fw-semibold mb-2">Einheit</label>
                                    <Select
                                        options={(units || []).map((entity) => ({
                                            id:  entity.id,
                                            text: entity.attributes.name
                                        }))}
                                        value={state.unitId || ''}
                                        onChange={(value) => setState((state) => ({
                                            ...state,
                                            unitId: value
                                        }))}
                                        multiple={false} placeholder={'Einheit'}></Select>
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className={"col-6 fv-row"}>
                                    <label className="fs-5 fw-semibold mb-2">Schläge</label>
                                    <Select options={fields.map((entity) => ({
                                        id:  entity.id,
                                        text: entity.attributes.name_with_hectare
                                    }))}
                                            onChange={(values) => setState((state) => ({...state, fields: values}))}
                                            multiple={true} disabled={!state.businessPartnerId}
                                            placeholder={'Schläge'}></Select>
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className={"col-12 fv-row"}>
                                    <label
                                        className="fs-5 fw-semibold mb-2">Kommentar <span>für interne Zwecke</span></label>
                                    <Textarea onChange={(value) => setState(state => ({...state, comment: value}))}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 fv-row">
                            <h3>Fahreraufträge</h3>

                            <StaffOrders staffOrders={staffOrders} staffs={staffs}
                                         services={services} time={time} staffId={staffId} />

                            <div className="row mt-5">
                                <Button onClick={() => setStaffOrders(staffOrders => [...staffOrders, {id: 1}])}>Fahrerauftrag
                                    hinzufügen</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </ModalContent>
            <ModalFooter>
                <button type="reset" className="btn btn-light me-3" onClick={onClose}>Cancel</button>
                <button type="submit" className="btn btn-primary" onClick={onSubmit}>
                    <span className="indicator-label">Save</span>
                    <span className="indicator-progress">
                        Please wait...
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                </button>
            </ModalFooter>
        </Modal>
    )
}

export default CreateOrderModal
