import React from "react";
import { useDrag } from 'react-dnd';
import * as style from './index.module.scss'

import classNames from 'classnames'

const StaffItem = ({staff, onClick, draggable = false}) => {
    const [{ isDragging }, drag] = useDrag(() => ({
        type: 'BOX',
        item: staff,
        collect: (monitor) => ({
            isDragging: draggable && !!monitor.isDragging(),
        }),
    }));

    return <button ref={drag} className={classNames(style['root'], 'card', 'mb-2', 'p-2')} onClick={onClick}>
        { staff['client_specific_number'] } { staff.name }
    </button>
}

export default StaffItem
