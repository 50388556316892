import React from "react";
import * as styles from './OrderItem.module.scss'

const groupRenderer = ({group}) => {

    return <a href={`/staffs/${group.id}`} className={'text-gray-800 text-hover-primary mb-1 fw-semibold'}
              target={'_blank'}>{group.title}</a>;
};

export default groupRenderer
