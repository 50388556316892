import React from "react";

const Checkbox = ({ size, label, input }) => {
  const boxSize = {
    "sm": "h-20px w-20px",
    "md": "h-30px w-30px",
    "lg": "h-40px w-40px",
  }

  return (
    <div className="form-check form-check-warning form-check-solid me-10">
      <input
        className={`form-check-input ${boxSize[size]}`}
        type="checkbox"
        checked={input.value}
        onChange={input.onChange}
      />
      <label className="form-check-label">
        {label}
      </label>
    </div>
  );
};

export default Checkbox;
