import React, { useMemo, useEffect } from 'react';
import { axiosInstance, routes } from '../../utils/api_base';
import { useTranslation } from 'react-i18next';
import { pushToHistory } from '../../utils/base';
import BaseTable from '../../components/Table';
import TableButton from '../../components/Table/TableButton';
import { isEmpty } from 'lodash';
import { Tooltip } from 'bootstrap'
import QuestionMark from '../../components/Icons/QuestionMark';

const Table = ({ driverCost, filters, tableToolbar }) => {
  const { t, i18n } = useTranslation()

  useEffect(() => {
    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
    const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl))
  }, []);

  const getMachines = async (pagination, sorting, globalFilter, customFilters) => {
    const { pageIndex, pageSize } = pagination
    const sortQuery = sorting.map((attr) => {
      return attr.desc ? `${attr.id} desc` : attr.id
    })
    let params = { page: pageIndex + 1, limit: pageSize, sort: sortQuery[0] }
    if (globalFilter) {
      params.query = globalFilter
    }
    if (!isEmpty(customFilters)) {
      params = { ...params, ...customFilters }
    }
    const res = await axiosInstance.get(routes.machineRepairs(), { params: { ...params, rental: true } })
    pushToHistory(params)

    return (
      { rows: res.data.data, pageCount: res.data.meta.total_pages, rowCount: res.data.meta.total_count }
    )
  }
  
  const costTooltip = () => {
    return (
      <>
        <span>{t('shared.actual_costs_repair_hours')}</span>
        <span
          data-bs-toggle="tooltip"
          data-bs-custom-class="tooltip-inverse"
          data-bs-placement="top"
          className="ps-1"
          title={t('shared.actual_costs_repair_title', { driver_cost: driverCost })}
        >
          <QuestionMark className="fs-4" />
        </span>
      </>
    )
  }

  const codeHtml = (row) => {
    return (
      <TableButton
        target="_blank"
        href={row?.original?.attributes?.link}
        className="btn-light-primary me-3"
        title={row?.original?.attributes?.client_specific_number}
      />
    )
  }

  const columns = useMemo(
    () => [
      {
        id: 'code',
        header: t('shared.number'),
        accessorFn: row => row?.attributes?.client_specific_number,
        size: 70,
        minSize: 70,
        enableSorting: true,
        cell: ({ row }) => {
          return codeHtml(row)
        },
      },
      {
        id: 'machine_group',
        minSize: 70,
        size: 70,
        accessorFn: row => row?.attributes?.group_name,
        header: t('shared.machine_group'),
        cell: row => row.getValue(),
      },
      {
        id: 'name',
        size: 70,
        minSize: 70,
        accessorFn: row => row?.attributes?.db_name,
        header: t('shared.name'),
        cell: row => row.getValue(),
      },
      {
        id: 'plan_repair_costs',
        accessorFn: row => row?.attributes?.plan_repair_costs,
        header: t('shared.plan_repair_costs'),
        cell: row => row.getValue(),
      },
      {
        id: 'actual_repair_hours',
        accessorFn: row => row?.attributes?.actual_repair_hours,
        header: t('shared.actual_repair_hours'),
        cell: row => row.getValue(),
      },
      {
        id: 'actual_costs_repair_hours',
        accessorFn: row => row?.attributes?.actual_costs_repair_hours,
        header: costTooltip(),
        cell: row => row.getValue(),
      },
      {
        id: 'spare_parts',
        size: 70,
        minSize: 70,
        accessorFn: row => row?.attributes?.spare_parts,
        header: t('shared.spare_parts'),
        cell: row => row.getValue(),
      }
    ],
    [i18n.language]
  )

  return (
    <BaseTable
      columns={columns}
      columnVisibility={{}}
      getDataFn={getMachines}
      tableToolbar={tableToolbar}
      filters={filters}
      initialSortDirection={'asc'}
    />
  )
}

export default Table;
