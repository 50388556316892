import React from 'react';
import Table from './Table';

const RentalMachines = ({ driver_cost, filters }) => {

	const tableToolbar = () => {
		return (
			<></>
		)
	}

	return (
		<Table filters={filters} tableToolbar={tableToolbar} driverCost={driver_cost} />
	)
}

export default RentalMachines;
